import React, { useContext } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import PickUp from './components/PickUp';
import Map from './components/Map';
import Expose from './components/Expose';
import { ICON_HTML } from './components/Icons';
import Checkin from './components/CheckIn';
import IFrame from './components/IFrame';
import Closeable from './components/Closeable';
import { CloseableEnum } from './enums';
import Story from './components/Story';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import SspProviderContext from './utils/SspProvider/context';

function Main() {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const editorialContent = contentModel.has('StoryItems');

    return (
        <div className='App'>
            <div className='svgtemplates' dangerouslySetInnerHTML={{ __html: ICON_HTML }} />
            <HashRouter>
                <Routes>
                    <Route path='/pick-up' element={<PickUp editorialContent={editorialContent} />} />
                    <Route path='/story' element={<Story editorialContent={editorialContent} />} />
                    <Route path='/map' element={<Map travel={false} editorialContent={editorialContent} />} />
                    <Route path='/travel-map' element={<Map travel={true} />} />
                    <Route path='/checkin' element={<Checkin />} />
                    <Route path='/opening' element={<Closeable content={CloseableEnum.Opening} />} />
                    <Route path='/contact' element={<Closeable content={CloseableEnum.Contact} />} />
                    <Route path='/iframe/:url' element={<IFrame />} />
                    <Route path='/' element={<Expose editorialContent={editorialContent} />} />
                    <Route path='/error' element={<h1>Page not found</h1>} />
                </Routes>
            </HashRouter>
        </div>
    );
}

export default Main;
