import { setOverlay } from '../../../utils/SspProvider/dispatchFunctions';
import React, { useContext } from 'react';
import SspProviderContext from '../../../utils/SspProvider/context';
import './chargingandonlineservices.scss';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import i18next from 'i18next';
import SliderPreviewTile from '../../PreviewTile/SliderPreviewTile';
import SortTiles from '../../../utils/SortTiles';

export default function ChargingAndOnlineServices(): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const charging: SSPContentVO[] = contentModel
        .reflist('HighlightEquipItems')
        .filter(equipItem => equipItem.asModel.target.Kategorie === 'charging');
    const connectivity: SSPContentVO[] = contentModel
        .reflist('HighlightEquipItems')
        .filter(equipItem => equipItem.asModel.target.Kategorie === 'digital_online_services');
    const practicalInformation = contentModel
        .reflist('HighlightEquipItems')
        .filter(equipItem => equipItem.asModel.target.Kategorie === 'practical_information');

    const handleTileClick = (item: SSPContentVO) => {
        setOverlay(store, {
            title: item.asModel.str('Name'),
            descriptionUpper: item.asModel.str('DescriptionUpper'),
            overlayImages: item.asModel.reflist('Assets'),
            descriptionLower: item.asModel.str('DescriptionBottom'),
            multiSlideOverlay: item.asModel.reflist('SubHighlightEquipItem'),
            link: item.asModel.str('Url'),
        });
    };

    SortTiles(charging);
    SortTiles(connectivity);
    SortTiles(practicalInformation);

    return (
        <div id='charging-and-online-services'>
            <div>
                {charging && charging.length !== 0 && (
                    <>
                        <h2>{i18next.t('Charging')}</h2>
                        <SliderPreviewTile items={charging} tileType={'large'} onClickCallback={handleTileClick} />
                    </>
                )}
            </div>
            <div>
                {connectivity && connectivity.length !== 0 && (
                    <>
                        <h2>{i18next.t('Connectivity')}</h2>
                        <SliderPreviewTile items={connectivity} tileType={'large'} onClickCallback={handleTileClick} />
                    </>
                )}
            </div>
            <div>
                {practicalInformation && practicalInformation.length !== 0 && (
                    <>
                        <h2>{i18next.t('PracticalInformation')}</h2>
                        <SliderPreviewTile
                            items={practicalInformation}
                            tileType={'large'}
                            onClickCallback={handleTileClick}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
