import React, { useContext, useState } from 'react';
import { OverlayEnum } from '../../../enums';
import SspProviderContext from '../../../utils/SspProvider/context';
import { setOverlay } from '../../../utils/SspProvider/dispatchFunctions';
import { checklistItems } from './checklistItems';

export default function Checklist(): JSX.Element {
    const store = useContext(SspProviderContext);

    const initialChecked = JSON.parse(localStorage.getItem('checkedItems') || '[]');
    const [checkedItems, setCheckedItems] = useState<Array<string>>(initialChecked);

    function cacheCheckedItem(item: string, oldList: Array<string>) {
        let newList = oldList;
        if (checkedItems.includes(item)) {
            const index = oldList.indexOf(item);
            if (index > -1) {
                newList.splice(index, 1);
            }
        } else {
            newList = [...oldList, item];
        }
        setCheckedItems([...newList]);
        localStorage.setItem('checkedItems', JSON.stringify([...newList]));
    }

    return (
        <div id='checklist'>
            <svg>
                <use xlinkHref='#checklisteSVG' />
            </svg>
            <div className='checklist-area'>
                <div className='dash-line' />
                <h1 className='big-headline line'>
                    <div className='blue-circle' />
                    HAKEN DRAN:
                    <br />
                    IHRE CHECKLISTE
                </h1>
                <p>
                    Werfen Sie einen Blick auf unsere Checkliste und haken Sie direkt ab, was schon abreisebereit in
                    Ihrer Tasche steckt. Alles Weitere können Sie Schritt für Schritt in unserer Checkliste "To Go"
                    nachtragen:
                </p>
                <div className='table-container'>
                    <div className='blue-blur-dark-left' />
                    <div className='blue-blur-light-left' />
                    <div className='blue-blur-light-right' />
                    <table className='checklist-table'>
                        <tbody>
                            {checklistItems.map((item, index) => (
                                <tr key={`checklist-row-${index}`}>
                                    <td>
                                        <>
                                            <div className='blue-checklist-dot' />
                                            {item.name}
                                            {item.info && (
                                                <span
                                                    className='in-circle'
                                                    onClick={() =>
                                                        setOverlay(store, {
                                                            enum: OverlayEnum.AdditionalInfo,
                                                            info: item.info,
                                                        })
                                                    }
                                                >
                                                    i
                                                </span>
                                            )}
                                            <div
                                                className={`checkbox${
                                                    checkedItems.includes(item.id) ? ' checked' : ''
                                                }`}
                                                onClick={() => cacheCheckedItem(item.id, checkedItems)}
                                            />
                                        </>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='checklist-box-area'>
                <div
                    className='angled-dash-line'
                    style={{
                        transform: `rotate(${Math.atan(35 / (window.innerWidth / 2 - 45))}rad)`,
                        width: Math.sqrt(35 ** 2 + (window.innerWidth / 2 - 45) ** 2),
                    }}
                />
                <div className='dash-line' />
                <div className='checklist-box'>
                    <h1>HINWEIS ZUR CHECKLISTE</h1>
                    <p>Informationen zur serienmäßigen Ausstattung Ihres neuen Fahrzeuges.</p>
                    <svg onClick={() => setOverlay(store, { enum: OverlayEnum.InfoChecklist })}>
                        <use xlinkHref='#arrow-link' />
                    </svg>
                </div>
                <div className='checklist-box'>
                    <h1>CHECKLISTE ALS PDF</h1>
                    <p>Hier können Sie Ihre Checkliste als PDF downloaden.</p>
                    <svg onClick={() => setOverlay(store, { download: checklistItems })} className={'arrow-download'}>
                        <use xlinkHref='#arrow-download' />
                    </svg>
                </div>
            </div>
        </div>
    );
}
