import React from 'react';

export const checklistItems = [
    { id: 'vehicleRegistration', name: 'Fahrzeugschein' },
    { id: 'personalID', name: 'Gültiger Personalausweis oder Reisepass' },
    { id: 'driversLicense', name: 'Führerschein' },
    { id: 'pickupTicket', name: 'Abholschein vom Händler' },
    { id: 'licensePlate', name: 'Kennzeichen Ihres neuen\nFahrzeuges' },
    {
        id: 'plateHolder',
        name: 'Kennzeichenträger',
        info: 'Bitte beachten Sie, dass bei vielen Modellen technisch bedingt nur ein Kennzeichenträger montierbar ist. Sprechen Sie vorab mit Ihrem Händler über die Möglichkeiten und vermeiden Sie so unnötiges Gepäck bei der Anreise.',
    },
    {
        id: 'chargeCard',
        name: 'We Charge Ladekarte',
        info: 'Nur bei Übernahmen von ID. Modellen oder eines e-up!. Bei Auslieferung hat die Batterie einen Ladezustand von 80%. Anschließend können Sie mit der We Charge Ladekarte an vielen Ladepunkten Strom für Ihren Neuwagen beziehen. Zur Aktivierung der We Charge Ladekarte im Neuwagen ist eine Kreditkarte (Visa/ Mastercard) erforderlich.',
    },
    {
        id: 'volkswagenID',
        name: 'Ihre Volkswagen ID',
        info: 'Die Volkswagen ID ist Ihr Schlüssel zur digitalen Welt von Volkswagen. Melden Sie sich an, um beispielsweise Ihren präferierten Händler zu speichern, Fahrzeuge in Ihrer virtuellen Garage zu verwalten oder die Vorteile und Funktionalitäten des Nutzerbereichs myVolkswagen zu entdecken.',
    },
    { id: 'onlineCheckin', name: 'Online Check-in' },
];
