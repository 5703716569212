import SSPContentExt from 'sspcontentext';
import { SspProviderState } from './interfaces';
import { OverlayType } from '../../components/Overlay/interfaces';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';

export const SET_CONTENT_ACTION = 'SET_CONTENT';
export const SET_SSP_ACTION = 'SET_SSP';
export const SET_OVERLAY_ACTION = 'SET_OVERLAY';
export const SET_LOADING_PROGRESS_ACTION = 'SET_LOADING_PROGRESS';
export const SET_EXPOSE_ARROW_HIDDEN_ACTION = 'SET_EXPOSE_ARROW_HIDDEN';
export const SET_VIDEO_STATE_ACTION = 'SET_VIDEO_STATE';

export type ACTION =
    | { type: typeof SET_CONTENT_ACTION; value: SSPContentVO }
    | { type: typeof SET_SSP_ACTION; value: SSPContentExt }
    | { type: typeof SET_OVERLAY_ACTION; value: OverlayType | null }
    | { type: typeof SET_LOADING_PROGRESS_ACTION; value: number }
    | { type: typeof SET_EXPOSE_ARROW_HIDDEN_ACTION; value: boolean }
    | { type: typeof SET_VIDEO_STATE_ACTION; value: null | string };

export const reducer = (state: SspProviderState, action: ACTION): SspProviderState => {
    switch (action.type) {
        case SET_CONTENT_ACTION:
            return {
                ...state,
                content: action.value,
            };
        case SET_SSP_ACTION:
            return {
                ...state,
                ssp: action.value,
            };
        case SET_OVERLAY_ACTION:
            return {
                ...state,
                overlay: action.value,
            };
        case SET_LOADING_PROGRESS_ACTION:
            return {
                ...state,
                loadingProgress: action.value,
            };
        case SET_EXPOSE_ARROW_HIDDEN_ACTION:
            return {
                ...state,
                exposeArrowHidden: action.value,
            };
        case SET_VIDEO_STATE_ACTION:
            return {
                ...state,
                videoState: action.value,
            };
        default:
            return state;
    }
};

export const INITIAL_STATE: SspProviderState = {
    content: null,
    ssp: null,
    overlay: null,
    loadingProgress: 0,
    exposeArrowHidden: false,
    videoState: null,
};
