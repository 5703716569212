import React, { useContext, useEffect, useRef, useState } from 'react';
import Indicators from '../../Indicators';
import CustomLink from '../../CustomLink';
import { setOverlay } from '../../../utils/SspProvider/dispatchFunctions';
import { OverlayEnum } from '../../../enums';
import SspProviderContext from '../../../utils/SspProvider/context';

export default function Plan(): JSX.Element {
    const [byCar, setByCar] = useState<boolean>(false);
    const speechBubbleRef = useRef<HTMLDivElement>(null);
    const speechBubbleContentRef = useRef<HTMLDivElement>(null);
    const store = useContext(SspProviderContext);

    function setSpeechBubble(i: number) {
        if (i === 0) {
            setByCar(false);
        } else {
            setByCar(true);
        }
    }

    useEffect(() => {
        if (speechBubbleRef.current && speechBubbleContentRef.current) {
            speechBubbleRef.current.setAttribute(
                'style',
                `
            --bubble-height: ${speechBubbleContentRef.current.offsetHeight}px;
         `
            );
        }
    }, [byCar]);

    return (
        <div id='plan'>
            <div className='blue-dotted-line' />
            <div className='green-dot green-dot-6' />
            <div className='green-dot green-dot-7' />
            <div className='blue-circle' />
            <h1 className='big-headline center line journey'>IHRE ANREISE</h1>
            <p>
                Wir möchten Sie dabei unterstützen, möglichst komfortabel und klimabewusst zu uns zu kommen, zum
                Beispiel mit dem Zug oder einem Mietwagen. Sie haben noch offene Fragen zu Ihrer Anreise? Kontaktieren
                Sie uns gern. Hier die wichtigsten Informationen auf einem Blick:
            </p>
            <div className='info-area'>
                <p>Wie reisen Sie an?</p>
            </div>
            <div className='travel-option-choice'>
                <div className='dashed-line-1' />
                <div className='dashed-line-3' />
                <div className='travel-options-container'>
                    <div className={`travel-option option-car${byCar ? ' active' : ''}`} onClick={() => setByCar(true)}>
                        <div className='dots-container'>
                            {!byCar && <div className='single-dot' />}
                            {byCar && (
                                <>
                                    <div className='triple-dot dot-1' />
                                    <div className='triple-dot dot-2' />
                                </>
                            )}
                        </div>
                        <svg>
                            <use xlinkHref='#byCarSVG' />
                        </svg>
                        <span>Mit dem Auto</span>
                    </div>
                    <div
                        className={`travel-option option-train${!byCar ? ' active' : ''}`}
                        onClick={() => setByCar(false)}
                    >
                        <div className='dots-container'>
                            {byCar && <div className='single-dot' />}
                            {!byCar && (
                                <>
                                    <div className='triple-dot dot-1' />
                                    <div className='triple-dot dot-2' />
                                </>
                            )}
                        </div>
                        <svg>
                            <use xlinkHref='#byTrainSVG' />
                        </svg>
                        <span>Mit dem Zug</span>
                    </div>
                </div>
            </div>
            <div className={`speech-bubble-backdrop${!byCar ? ' blue' : ' dark-blue'}`} />
            <div className={`travel-option-speech-bubble${byCar ? ' blue' : ' dark-blue'}`} ref={speechBubbleRef}>
                <div className='travel-option-speech-bubble-content' ref={speechBubbleContentRef}>
                    {!byCar && (
                        <>
                            <b>Anreise:</b>
                            Vom Hauptbahnhof Wolfsburg aus erreichen Sie die Autostadt in wenigen Minuten zu Fuß über
                            die Stadtbrücke. Ein kurzer Spaziergang führt Sie direkt zur gläsernen Empfangshalle.
                            <br />
                            <br />
                            Weitere Informationen und Buchungsmöglichkeiten finden Sie hier:
                            <a
                                onClick={() => {
                                    setOverlay(store, {
                                        enum: OverlayEnum.ExternalContent,
                                        media: 'https://www.bahn.de',
                                    });
                                }}
                                rel='noreferrer'
                                target='_blank'
                            >
                                www.bahn.de
                            </a>
                            Aktuelle Meldungen und Verspätungen der Deutschen Bahn:
                            <a
                                onClick={() => {
                                    setOverlay(store, {
                                        enum: OverlayEnum.ExternalContent,
                                        media: 'https://www.bahn.de/service/fahrplaene?dbkanal_007=L01_S01_D001_KIN_-rs-NAVIGATION-auskunft_LZ01',
                                    });
                                }}
                                target='_blank'
                                rel='noreferrer'
                            >
                                Mehr erfahren
                            </a>
                            Besuchen Sie unsere interaktive Karte:
                            <CustomLink to='/travel-map'>Interaktive Anreisekarte</CustomLink>
                        </>
                    )}
                    {byCar && (
                        <>
                            <b>Anreise:</b>
                            Fahren Sie auf der Autobahn A2 am Kreuz Wolfsburg/Königslutter (58) auf die Autobahn A39 in
                            Richtung Wolfsburg und nutzen Sie die Abfahrt Wolfsburg/West. Folgen Sie der Beschilderung
                            zur Autostadt und orientieren Sie sich dann auf unserem Gelände an der Ausschilderung
                            „Kurzzeitparker/Abholer". Hier sind in unmittelbarer Nähe für Ihre Fahrzeuge kostenfreie
                            Parkplätze vorhanden. Die Zieladresse für Ihr Navigationssystem lautet: Berliner Brücke,
                            38440 Wolfsburg.
                            <b>Mietwagen:</b>
                            Ihren Mietwagen, z.B. der Anbieter Avis, Sixt und Europcar, können Sie täglich zwischen
                            09:00 Uhr und 16:45 Uhr voll geladen bzw. voll getankt beim Autostadt-Team am
                            Parkinghäuschen auf dem Kurzzeitparkplatz abgeben. Nutzen Sie zur Orientierung bitte vor Ort
                            die Beschilderung "Mietwagenannahme". Mehrkosten können dabei anfallen. Bitte informieren
                            Sie sich vorab bei Ihrem Anbieter.
                            <b>Laden von Elektroautos:</b>
                            Ihr Elektroauto kann während Ihres Aufenthaltes bequem geladen werden. Auf dem Parkplatz am
                            ServiceHaus stehen dazu mehrere Ladestationen bereit.
                            <br />
                            <br />
                            <a
                                href='https://www.autostadt.de/service-kontakt/anreise/ladesaeulen'
                                target='_blank'
                                rel='noreferrer'
                            >
                                Informationen zu den Ladesäulen
                            </a>
                            <b>Aktuelle Verkehrsinformationen:</b>
                            Sie reisen in die Autostadt und möchten sich über die aktuelle Verkehrslage informieren?
                            Dann finden Sie auf diesen Seiten hilfreiche Informationen zur aktuellen Verkehrslage:
                            <br />
                            <br />
                            Bau- und Planungsprojekte an niedersächsischen Bundesautobahnen
                            <a
                                onClick={() => {
                                    setOverlay(store, {
                                        enum: OverlayEnum.ExternalContent,
                                        media: 'https://www.strassenbau.niedersachsen.de/startseite/die_autobahn_gmbh/planungs_und_bauprojekte_auf_bundesautobahnen/',
                                    });
                                }}
                                target='_blank'
                                rel='noreferrer'
                            >
                                Mehr erfahren
                            </a>
                            Baustellen und Straßensperrungen im Stadtgebiet Wolfsburg
                            <a
                                onClick={() => {
                                    setOverlay(store, {
                                        enum: OverlayEnum.ExternalContent,
                                        media: 'https://www.wolfsburg.de/leben/mobilitaetverkehr/baustellen',
                                    });
                                }}
                                target='_blank'
                                rel='noreferrer'
                            >
                                Mehr erfahren
                            </a>
                            Besuchen Sie unsere interaktive Karte:
                            <CustomLink to='/travel-map'>Interaktive Anreisekarte</CustomLink>
                        </>
                    )}
                    {/*<b>*/}
                    {/*    <br/>*/}
                    {/*    <br/>*/}
                    {/*    <span onClick={() => props.setIframe('https://www.autostadt.de/service-kontakt/anreise')}>*/}
                    {/*    Weitere Informationen zur Anreise*/}
                    {/*</span>*/}
                    {/*    <br/>*/}
                    {/*    <br/>*/}
                    {/*    <CustomLink to='/travel-map'>*/}
                    {/*        <span>Interaktive Anreisekarte</span>*/}
                    {/*    </CustomLink>*/}
                    {/*</b>*/}
                    <Indicators length={2} active={!byCar ? 1 : 2} color={'white'} setActive={setSpeechBubble} />
                </div>
            </div>
        </div>
    );
}
