import React, { useContext } from 'react';
import Page from '../Page';
import { PageChildrenEnum } from '../../enums';
import './styles.scss';
import Overview from './Sections/Overview';
import Gallery from './Sections/Gallery';
import Details from './Sections/Details';
import SspProviderContext from '../../utils/SspProvider/context';
import Highlights from './Sections/Highlights';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import { getHighlights } from './utils/getHighlights';
import { EditorialContentProps } from '../Page/interfaces';
import Links from './Sections/Links';
import ChargingAndOnlineServices from './Sections/ChargingAndOnlineServices';

export default function Expose(props: EditorialContentProps): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const EditorialHighlights = contentModel.has('HighlightEquipItems');
    const EditorialLinks = contentModel.has('EditorialLinklist');

    return (
        <Page child={PageChildrenEnum.Expose} editorialContent={props.editorialContent}>
            <div className='expose'>
                <Overview />
                {EditorialHighlights && getHighlights(contentModel).length > 0 && <Highlights />}
                {EditorialHighlights && <ChargingAndOnlineServices />}
                <Gallery editorialContent={props.editorialContent} />
                <Details editorialContent={EditorialHighlights} />
                {EditorialLinks && <Links />}
            </div>
        </Page>
    );
}
