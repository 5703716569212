import './styles.scss';
import { useEffect, useMemo, useState } from 'react';

interface PaginationProps {
    currentButton: number;
    numberOfPages: number | undefined;
    onClick: (index: number) => void;
}

export default function Pagination(props: PaginationProps): JSX.Element {
    const numberOfPages = useMemo(
        () => props.numberOfPages && Array.from({ length: props.numberOfPages }, (_, i) => i + 1),
        [props.numberOfPages]
    );
    const currentButton = props.currentButton + 1;
    const [pagesToDisplay, setPagesToDisplay] = useState<(number | string)[]>([]);

    useEffect(() => {
        if (numberOfPages) {
            const dots = '...';
            let tempNumberOfPages: (number | string)[] = [...numberOfPages];
            if (numberOfPages.length > 4) {
                if (currentButton >= 1 && currentButton <= 2) {
                    tempNumberOfPages = [1, 2, 3, dots];
                } else if (currentButton === 3) {
                    const sliced = numberOfPages.slice(1, 3);
                    tempNumberOfPages = [...sliced, dots, numberOfPages.length];
                } else if (currentButton > 3 && currentButton <= numberOfPages.length - 2) {
                    const slicedStart = numberOfPages.slice(currentButton - 2, currentButton);
                    const slicedEnd = numberOfPages.slice(currentButton, currentButton);
                    tempNumberOfPages = [...slicedStart, ...slicedEnd, dots, numberOfPages.length];
                } else if (currentButton > numberOfPages.length - 2) {
                    const sliced = numberOfPages.slice(numberOfPages.length - 3);
                    tempNumberOfPages = [dots, ...sliced];
                }
            }

            setPagesToDisplay(tempNumberOfPages);
        }
    }, [currentButton, numberOfPages]);

    return (
        <div className='pagination'>
            {pagesToDisplay.map((page, index) => (
                <button
                    key={index}
                    className={`${currentButton === page && (page as number) > 9 ? 'active extra-width' : currentButton === page ? 'active' : ''}`}
                    onClick={() => {
                        if (typeof page === 'number') {
                            props.onClick(page - 1);
                        }
                    }}
                >
                    {page}
                </button>
            ))}
        </div>
    );
}
