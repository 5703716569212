import React, { useState } from 'react';
import Page from '../Page';
import { CloseableEnum, PageChildrenEnum } from '../../enums';
import './styles.scss';

export default function Closeable(props: { content: CloseableEnum }): JSX.Element {
    const [iFrameSrc, setIFrameSrc] = useState<string | undefined>(undefined);

    return (
        <>
            <Page iframeSrc={iFrameSrc} child={PageChildrenEnum.Closeable}>
                {props.content === CloseableEnum.Privacy && (
                    <div className='privacy'>
                        <h1 className='big-headline line upper'>Datenschutz</h1>
                        Im Folgenden möchten wir Sie darüber informieren, welche Daten während Ihres Besuches auf den
                        Autostadt Webseiten erfasst werden und wie genau diese verwendet werden.
                        <br />
                        <br />
                        „Autostadt“ bezeichnet die Autostadt GmbH als Betreiberin der Webseiten www.autostadt.de, nebst
                        Unterseiten mit dem Online-Shop „Autostadt ZeitReise E-Shop“.
                        <br />
                        <br />
                        <span className='upper'>
                            <b>A. ALLGEMEINE HINWEISE</b>
                        </span>
                        <br />
                        <br />
                        <span className='blue'>1. Wer ist für die Datenverarbeitung verantwortlich?</span>
                        <br />
                        <br />
                        Verantwortlicher im Sinne des Datenschutzrechts ist die
                        <br />
                        <br />
                        <b>
                            Autostadt GmbH
                            <br />
                            Stadtbrücke
                            <br />
                            38440 Wolfsburg
                            <br />
                            E-Mail: <a href='mailto:service@autostadt.de'>service@autostadt.de</a>
                        </b>
                        <br />
                        <br />
                        Weitere Informationen zu unserem Unternehmen, insbesondere Angaben zu den
                        vertretungsberechtigten Personen und zusätzliche Kontaktmöglichkeiten finden Sie im Impressum
                        unserer Internetseite: <br />
                        <br />
                        <a
                            onClick={() => {
                                setIFrameSrc('https://autostadt.de/footer/impressum');
                            }}
                        >
                            https://www.autostadt.de/footer/impressum
                        </a>
                        <br />
                        <br />
                        Kontaktdaten unseres Datenschutzbeauftragten:
                        <br />
                        <br />
                        <b>
                            Datenschutzbeauftragter der Autostadt GmbH
                            <br />
                            Volkswagen AG
                            <br />
                            Brieffach 8091
                            <br />
                            38436 Wolfsburg
                            <br />
                            <a href='tel:05361 924333'>Tel. 05361 / 9-24333</a>
                            <br />
                            <a href='mailto:konzerndatenschutz@volkswagen.de'>konzerndatenschutz@volkswagen.de</a>
                        </b>
                        <br />
                        <br />
                        Sofern die folgenden Abschnitte keine gegenteiligen Angaben enthalten, erfolgt die
                        Datenverarbeitung ausschließlich durch die Autostadt GmbH.
                        <br />
                        <br />
                        <span className='blue'>2. Was sind personenbezogene Daten?</span>
                        <br />
                        <br />
                        Die Datenschutzgesetze schützen personenbezogene Daten. Personenbezogene Daten sind alle
                        Informationen, die sich auf eine bestimmte oder bestimmbare natürliche Person (einen Menschen)
                        beziehen. Hierunter fallen z.B. Name, Post- oder E-Mail-Adresse einer Person, allerdings auch
                        die Angabe, wann diese Person sich welche Seite angesehen hat.
                        <br />
                        <br />
                        Hierbei unterscheiden wir im Wesentlichen folgende Datenkategorien:
                        <br />
                        <br />
                        <b>&gt; Private Kontakt- und Identifikationsdaten</b>
                        <br />
                        (Name, Vorname, Geschlecht, Anschrift, E-Mail-Adresse, Telefonnummer, Mobiltelefonnummer,
                        Geburtsdatum/-ort, Identifikationsnummern, Nationalität, etc.).
                        <br />
                        <b>&gt; Bonitäts- und Bankdaten</b>
                        <br />
                        (Zahlungsverhalten, Bilanzen, Daten von Auskunfteien, Scorewerte, Vermögensverhältnisse,
                        Kontoverbindung, Kreditkartennummer, etc.)
                        <br />
                        <b>&gt; Berufliche Kontakt- und (Arbeits-) Organisationsdaten</b>
                        <br />
                        (Name, Vorname, Geschlecht, Anschrift, E-Mail-Adresse, Telefonnummer, Mobiltelefonnummer,
                        Gesellschaft, Bereich, Abteilung, Kostenstelle, Personalnummern, Zuständigkeiten, Funktionen,
                        Anwesenheit (Ja/Nein), etc)
                        <br />
                        <b>&gt; Entgelt- und Zeitwirtschaftsdaten</b>
                        <br />
                        (Tarifgruppe, Entgeltabrechnung, Sonderzahlungen, Pfändung, tägliche Anwesenheitszeiten,
                        Abwesenheitsgründe, etc.)
                        <br />
                        <b>&gt; Vertragsdaten </b>
                        <br />
                        (gekaufte Produkte, Dienstleistungen, Datum Kaufvertrag, Kaufpreis, Sonderausstattung,
                        Garantien, etc.)
                        <br />
                        <b>&gt; Daten zu persönlichen/beruflichen Verhältnissen & Merkmalen</b>
                        <br />
                        (Daten zum Ehegatten oder Kindern, Familienstand, Portraitfoto, Ehrenamt, Berufsbezeichnung,
                        beruflicher Werdegang, Betriebszugehörigkeit, Aufgaben, Tätigkeiten, Eintritts- und
                        Austrittsdaten, Qualifikationen, Bewertungen / Beurteilungen, etc.)
                        <br />
                        <b>&gt; Besonders sensible personenbezogene Daten</b>
                        <br />
                        (Art. 9 DSGVO: „rassische und ethnische Herkunft, politische Meinungen, religiöse* oder
                        weltanschauliche Überzeugungen oder die Gewerkschaftszugehörigkeit, sowie die Verarbeitung von
                        genetischen Daten, biometrischen Daten zur eindeutigen Identifizierung einer natürlichen Person,
                        Gesundheitsdaten oder Daten zum Sexualleben oder der sexuellen Orientierung“)
                        <br />
                        <b>&gt; Straftaten / Ordnungswidrigkeiten</b>
                        <br />
                        (Daten, die sich auf strafbare Handlungen oder den Verdacht auf solche beziehen, besondere
                        Anforderungen nach Art. 10 DSGVO)
                        <br />
                        <b>&gt; IT-Nutzungsdaten</b>
                        (User-ID, Rollen, Berechtigungen, Login-Zeiten, Rechnername, IP-Adresse, GID, Legic-Nr., etc.)
                        <br />
                        <b>
                            &gt; Kfz-Nutzungs-Daten mit FIN/KfZ-Kennzeichen Garantie-, Gewährleistung, Produkthaftung,
                            sicherer Fahrzeugbetrieb
                        </b>
                        <br />
                        (Bei der Kfz-Nutzung anfallende Daten, die mit FIN/KfZ-Kennzeichen verknüpft sind und im
                        Zusammenhang mit Werkstattreparaturen, Garantie- sowie Gewährleistungen oder der Produkthaftung
                        von Bedeutung sind oder deren Verfügbarkeit für den sicheren Fahrzeugbetrieb erforderlich ist.)
                        <br />
                        <b>
                            &gt; Kfz-Nutzungs-Daten mit FIN/KfZ-Kennzeichen Komforteinstellungen, Multimedia, Navigation
                        </b>
                        <br />
                        (Bei der Kfz-Nutzung anfallende Daten, die mit FIN/KfZ-Kennzeichen verknüpft sind und
                        Komforteinstellungen betreffen, wie z.B. Sitzeinstellung, bevorzugte Radiosender,
                        Klimaeinstellungen, Navigationsdaten, E-Mail-/SMS-Kontaktdaten etc..)
                        <br />
                        <b>&gt; Kfz-Nutzungs-Daten mit FIN/KfZ-Kennzeichen Assistenzsysteme, Fahrverhalten etc.</b>
                        <br />
                        (Bei der Kfz-Nutzung anfallende Daten, die mit FIN/KfZ-Kennzeichen verknüpft sind und das
                        Fahrverhalten betreffen bzw. die Nutzung von Assistenzsystemen und deren konkrete Einsatzdaten
                        etc.)
                        <br />
                        <b>&gt; Positionsdaten</b>
                        <br />
                        (GPS, Funknetz-Ortung, Bewegungsprofil, WLAN-Hotspot-Ortung, etc.) Darüber hinaus können dies
                        auch Auftragsdaten (z.B. Zahlungsauftrag), Daten aus der Erfüllung unserer vertraglichen
                        Verpflichtungen (z.B. Umsatzdaten im Zahlungsverkehr), Informationen über Ihre finanzielle
                        Situation (z.B. Bonitätsdaten, Scoring-/ Ratingdaten), Werbe- und Vertriebsdaten,
                        Dokumentationsdaten sowie andere mit den genannten Kategorien vergleichbare Daten sein.
                        <br />
                        <br />
                        <span className='upper'>
                            <b>B. ERHEBUNG, VERARBEITUNG UND NUTZUNG IHRER PERSONENBEZOGENEN DATEN</b>
                        </span>
                        <br />
                        <br />
                        <span className='blue'>1. Zugriff auf die Website, Zugriff auf Dateien</span>
                        <br />
                        <br />
                        Sie können unsere Website grundsätzlich besuchen, ohne Angaben zu Ihrer Person zu machen. Wir
                        erfahren dann nur automatisch die folgenden Protokolldaten:
                        <br />
                        <br />
                        1. Eine anonyme Cookie-ID, die keinen Rückschluss auf Ihre IP-Adresse ermöglicht,
                        <br />
                        2. das von Ihnen genutzte Betriebssystem, den von Ihnen genutzten
                        <br />
                        Web-Browser und die von Ihnen eingestellte Bildschirmauflösung,
                        <br />
                        3. das Datum und die Uhrzeit Ihres Besuchs,
                        <br />
                        4. die Websites, die Sie bei uns besucht haben und
                        <br />
                        5. die Website, von der Sie uns besuchen.
                        <br />
                        <br />
                        Es handelt sich hier um eine typische technische Aufzeichnung, die quasi jeder Webserver
                        vornimmt.
                        <br />
                        <br />
                        Im Falle von fehlerhaften oder nicht internetkonformen Aufrufen unserer Website speichern wir
                        die vorstehenden Daten zusätzlich mit Ihrer IP-Adresse. Diese Protokollierung verwenden wir, um
                        technische Fehler unserer Website erkennen und beheben zu können, letztlich also, um die
                        Qualität unserer Website in technischer Hinsicht zu verbessern. Die Daten werden wir aber auch
                        verwenden, um eine missbräuchliche Nutzung unserer Website oder Angriffe auf sie aufzudecken und
                        sie zukünftig zu verhindern. Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit. f)
                        DSGVO; unsere berechtigten Interessen: Gewährleistung der Sicherheit und des Betriebs der
                        Website, bedarfsgerechte Gestaltung der Website und Verhinderung von Missbrauch, Aufklärung von
                        Straftaten, soweit nicht ausschließlich zur Erfüllung gesetzlicher Vorgaben. Sofern die Daten
                        für die vorgenannten Zwecke nicht mehr benötigt werden, löschen wir diese Daten nach 30 Tagen.
                        <br />
                        <br />
                        <span className='blue'>2. Kontaktformular</span>
                        <br />
                        <br />
                        An verschiedenen Stellen halten wir Formulare für Sie bereit, über die Sie mit uns in Kontakt
                        treten können.
                        <br />
                        <br />
                        Bei einer Kontaktaufnahme mit uns mittels Kontaktformular werden personenbezogene Daten von
                        Ihnen automatisch gespeichert, wenn Sie die jeweiligen Felder des Formulars ausfüllen und die
                        Daten absenden. Es obliegt Ihrer freien Entscheidung, ob und welche Informationen Sie uns auf
                        diesem Wege mitteilen. Zu den erhobenen personenbezogenen Daten zählen insoweit insbesondere
                        Ihre Kontaktdaten, wie etwa Vorname, Name, E-Mail-Adresse.
                        <br />
                        <br />
                        Ihre Angaben speichern wir zum Zweck der Bearbeitung Ihrer Anfrage sowie für den Fall, dass
                        Anschlussfragen entstehen (Rechtsgrundlage: Art. 6 Abs. 1 lit. b) DSGVO).
                        <br />
                        <br />
                        <span className='blue'>
                            3. Buchung von Tageskarten, Tickets für Veranstaltungen und Führungen
                        </span>
                        <br />
                        <br />
                        Wenn Sie bei uns Tageskarten, Tickets für Veranstaltungen, Führungen, Fahrerlebnisse,
                        Highlights, Familienangebote und Bildungsangebote buchen, werden personenbezogene Daten insoweit
                        erhoben, verarbeitet und genutzt, als dies die Auftragsabwicklung und die Inanspruchnahme der
                        Leistungen durch Sie erfordert oder Sie die Angaben hierzu freiwillig gemacht haben
                        (Rechtsgrundlagen: Art. 6 Abs. 1 lit. b) und f) DSGVO).
                        <br />
                        <br />
                        Verarbeitete Daten können sein:
                        <br />
                        <br />
                        {'>'} Private Kontakt- und Identifikationsdaten
                        <br />
                        {'>'} Bonitäts- und Bankdaten
                        <br />
                        {'>'} Berufliche Kontakt- und (Arbeits-) Organisationsdaten
                        <br />
                        {'>'} Buchungsanfragen {'>'} Vertragsdaten
                        <br />
                        {'>'} Besonders sensible personenbezogene Daten, wie z.B. eine Mobilitätseinschränkung (Diese
                        Daten werden nur erhoben und verarbeitet, wenn Sie dies bei Ihrer Buchung freiwillig angeben.)
                        <br />
                        <br />
                        Um Tickets buchen zu können, leiten wir Sie auf die Seiten der Regiondo GmbH, Grafinger Str. 6,
                        81671 München, www.regiondo.de über. Sie können dies daran erkennen, dass die Internetadresse
                        autostadt.regiondo.de lautet.
                        <br />
                        <br />
                        Während der Buchung werden Kontaktinformationen verpflichtend aufgenommen (Vorname, Nachname,
                        E-Mail und Telefon). Eine Bezahlung ist per Kreditkarte (MasterCard, Visa, American Express),
                        Lastschrift, PayPal oder Klarna Kauf auf Rechnung möglich. Für die einzelnen Bezahloptionen
                        werden u.U. weitere Daten erhoben (Adresse, Geburtsdatum, IBAN, BIC, Kreditkartennummer,
                        Karteninhaber und die Kartenprüfnummer).
                        <br />
                        <br />
                        Diese Seiten liegen in der Verantwortung der Regiondo GmbH. Die Regiondo GmbH tritt als
                        eigenständiger Vertriebspartner auf und übernimmt auch den Zahlungseinzug.
                        <br />
                        <br />
                        Die Regiondo GmbH übermittelt uns nach abgeschlossener Buchung dann
                        <br />
                        {'>'} Private Kontakt- und Identifikationsdaten
                        <br />
                        {'>'} Vertragsdaten
                        <br />
                        {'>'} Besonders sensible personenbezogene Daten
                        <br />
                        <br />
                        Mit vollständiger Abwicklung des Vertrages werden Ihre Daten für die weitere Verwendung gesperrt
                        und nach Ablauf der steuer- und handelsrechtlichen Vorschriften gelöscht, sofern Sie nicht
                        ausdrücklich in die weitere Nutzung Ihrer Daten eingewilligt haben.
                        <br />
                        <br />
                        <span className='blue'>4. Bestellung im von Waren</span>
                        <br />
                        <br />
                        Wenn Sie in unserem ZeitReise E-Shop Waren bestellen und ein Kundenkonto eröffnen, werden
                        personenbezogene Daten insoweit erhoben, verarbeitet und genutzt, als dies die
                        Auftragsabwicklung durch Sie erfordert oder Sie die Angaben hierzu freiwillig gemacht haben
                        (Rechtsgrundlagen: Art. 6 Abs. 1 lit. b) und f) DSGVO).
                        <br />
                        <br />
                        Verarbeitete Daten können sein:
                        <br />
                        <br />
                        {'>'} Private Kontakt- und Identifikationsdaten
                        <br />
                        {'>'} Bonitäts- und Bankdaten
                        <br />
                        {'>'} Vertragsdaten
                        <br />
                        <br />
                        Wir benötigen diese Daten, um Ihnen Ihre Rechnung und Ihre bestellte Ware zukommen zu lassen.
                        Die Angabe der E-Mail-Adresse dient der Erstellung eines Kundenkontos und der Kontaktaufnahme
                        durch uns, sollte es zu Rückfragen oder Lieferengpässen hinsichtlich Ihrer Bestellung kommen.
                        <br />
                        <br />
                        Zur Ausführung Ihrer Bestellung übermitteln wir Ihre Daten an Zahlungs- wie auch
                        Transportdienstleister weiter.
                        <br />
                        <br />
                        Mit vollständiger Abwicklung des Vertrages und vollständiger Kaufpreiszahlung werden Ihre Daten
                        für die weitere Verwendung gesperrt und nach Ablauf der steuer- und handelsrechtlichen
                        Vorschriften gelöscht, sofern Sie nicht ausdrücklich in die weitere Nutzung Ihrer Daten
                        eingewilligt haben.
                        <br />
                        <br />
                        Ihre personenbezogenen Daten werden ausschließlich zu dem Zweck erhoben, verarbeitet und
                        genutzt, um Ihnen die Nutzung des Autostadt ZeitReise E-Shop zu ermöglichen und Ihre Bestellung
                        abzuwickeln. Eine Erhebung Ihrer personenbezogenen Daten erfolgt dabei nur in dem Umfang, in
                        welchem Sie selbst uns Ihre Daten zur Verfügung stellen, z.B. im Rahmen Ihrer Bestellung oder
                        Registrierung.
                        <br />
                        <br />
                        <span className='blue'>5. Tischreservierung</span>
                        <br />
                        <br />
                        Wenn Sie über unsere Website eine Online-Tischreservierung für eines der Autostadt Restaurants
                        vornehmen, verwenden wir Ihre Daten, um diese Tischreservierung an das jeweilige Restaurant
                        weiterzuleiten. Hierbei werden personenbezogene Daten insoweit erhoben, verarbeitet und genutzt,
                        als dies die Auftragsabwicklung und die Inanspruchnahme der Leistungen durch Sie erfordert oder
                        Sie die Angaben hierzu freiwillig gemacht haben (Rechtsgrundlagen: Art. 6 Abs. 1 lit. b) und f)
                        DSGVO).
                        <br />
                        <br />
                        Verarbeitete Daten können sein:
                        <br />
                        <br />
                        {'>'} Private Kontakt- und Identifikationsdaten
                        <br />
                        {'>'} Vertragsdaten
                        <br />
                        {'>'} Besonders sensible personenbezogene Daten, wie z.B. eine Lebensmittelunverträglichkeit
                        (Diese Daten werden nur erhoben und verarbeitet, wenn Sie dies bei Ihrer Buchung freiwillig
                        angeben.)
                        <br />
                        <br />
                        Die Autostadt GmbH betreibt die Restaurants nicht sondern übermittelt die Informationen an den
                        jeweiligen Restaurantbetreiber, insbesondere Mövenpick.
                        <br />
                        <br />
                        Wir speichern die Daten lediglich zur technischen Übermittlung an das jeweilige Restaurant. Die
                        Daten werden nach Übermittlung auf unseren Systemen gelöscht.
                        <br />
                        <br />
                        <span className='blue'>6. Nutzung unseres Tagesplaners</span>
                        <br />
                        <br />
                        Sie haben auf unserer Website die Möglichkeit, einen individuellen Tagesplan für Ihren
                        Aufenthalt in der Autostadt über autostadt.de/tagesplaner zu gestalten.
                        <br />
                        <br />
                        Hierbei werden personenbezogene Daten insoweit erhoben, verarbeitet und genutzt, als dies die
                        Auftragsabwicklung und die Inanspruchnahme der Leistungen durch Sie erfordert oder Sie die
                        Angaben hierzu freiwillig gemacht haben (Rechtsgrundlagen: Art. 6 Abs. 1 lit. b) und f) DSGVO).
                        <br />
                        <br />
                        Verarbeitete Daten können sein:
                        <br />
                        <br />
                        {'>'} Private Kontakt- und Identifikationsdaten
                        <br />
                        {'>'} Vertragsdaten
                        <br />
                        {'>'} Besonders sensible personenbezogene Daten, wie z.B. eine Mobilitätseinschränkung (Diese
                        Daten werden nur erhoben und verarbeitet, wenn Sie dies bei Ihrer Buchung freiwillig angeben.)
                        Über einen Wunschlisten-Code können Sie die gespeicherten Daten jeweils wieder aufrufen.
                        <br />
                        <br />
                        Im Falle einer Buchung der Attraktionen übermitteln wir, sofern die Autostadt GmbH die
                        Leistungen nicht selbst erbringt, an den jeweiligen Fremddienstleister, die die Autostadt bei
                        Erbringung ihrer Leistungen in Anspruch nimmt.
                        <br />
                        <br />
                        <span className='blue'>7. Jahreskartentausch</span>
                        <br />
                        <br />
                        Als Jahreskarteninhaber bieten wir Ihnen an, einen Jahreskartentausch online anzustoßen.
                        <br />
                        <br />
                        Hierbei werden personenbezogene Daten insoweit erhoben, verarbeitet und genutzt, als dies die
                        Auftragsabwicklung durch Sie erfordert (Rechtsgrundlage: Art. 6 Abs. 1 lit. b) DSGVO).
                        <br />
                        <br />
                        Verarbeitete Daten können sein:
                        <br />
                        <br />
                        {'>'} Private Kontakt- und Identifikationsdaten
                        <br />
                        {'>'} Vertragsdaten
                        <br />
                        {'>'} Daten zu persönlichen/beruflichen Verhältnissen & Merkmalen (Portraitfoto) Sofern wir mit
                        Ihrer Einwilligung noch ein Foto von Ihnen gespeichert haben, werden wir dieses zur Ausstellung
                        Ihrer neuen Jahreskarte verwenden.
                        <br />
                        <br />
                        <br />
                        <br />
                        <span className='blue'>8. Eröffnen eines Online-Accounts</span>
                        <br />
                        <br />
                        Sie haben an verschiedenen Stellen unserer Website die Möglichkeit, sich für einen
                        Online-Account zu registrieren. Wenn Sie dies tun und bestimmte Funktionen oder Services der
                        Website nutzen wollen, werden personenbezogene Daten von Ihnen in Form von Bestands- und
                        Nutzungsdaten insoweit erhoben, verarbeitet und genutzt, als dies die Auftragsabwicklung und die
                        Inanspruchnahme der Dienste durch Sie erfordert oder Sie die Angaben hierzu freiwillig gemacht
                        haben. (Rechtsgrundlagen: Art. 6 Abs. 1 lit. b) und f) DSGVO).
                        <br />
                        <br />
                        <strong>Verarbeitete Daten können sein:</strong>
                        <br />
                        <br />
                        {'>'} Private Kontakt- und Identifikationsdaten
                        <br />
                        {'>'} Berufliche Kontakt- und (Arbeits-) Organisationsdaten
                        <br />
                        {'>'} Daten zu persönlichen/beruflichen Verhältnissen
                        <br />
                        {'>'} Daten zu persönlichen/beruflichen Verhältnissen & Merkmalen
                        <br />
                        {'>'} Besonders sensible personenbezogene Daten (Diese Daten werden nur erhoben und verarbeitet,
                        wenn Sie diese freiwillig angeben.)
                        <br />
                        {'>'} IT-Nutzungsdaten
                        <br />
                        {'>'} Vertragsdaten
                        <br />
                        <br />
                        Wenn Sie Ihren Account löschen, werden die zugehörigen Daten ebenfalls gelöscht, sofern steuer-
                        und handelsrechtliche Vorschriften keine weitergehende Speicherung erfordern.
                        <br />
                        <br />
                        <br />
                        <br />
                        <span className='blue'>9. Newsletteranmeldung</span>
                        <br />
                        <br />
                        Diese Webseite bietet verschiedene Möglichkeiten, einen Newsletter zu bestellen. Die in diesem
                        Zusammenhang angegeben Daten (E-Mail-Adresse und optional Name, Vorname, Interessen) werden von
                        uns ausschließlich zum Zweck der Erbringung dieses Services im Rahmen Ihrer gegebenen
                        Einwilligung genutzt (Art. 6 Abs. 1 S. 1 lit. a) DSGVO). Die Autostadt GmbH verwendet hierfür
                        das sog. Double-Opt-In-Verfahren. Dabei wird Ihnen eine E-Mail an die angegebene E-Mail-Adresse
                        mit der Bitte um Bestätigung Ihrer Einwilligung gesendet. Sie können Ihre Einwilligung jederzeit
                        unentgeltlich mit Wirkung für die Zukunft gegenüber der Autostadt GmbH widerrufen, z. B. über
                        den in jedem Newsletter enthaltenen Link „Abbestellen". Sofern Sie den Newsletter abbestellen,
                        werden Ihre personenbezogenen Daten gelöscht, sofern keine anderen Aufbewahrungsfristen (z. B.
                        steuerliche Aufbewahrungsfristen) entgegenstehen. Der Versand des Newsletters erfolgt durch den
                        Einsatz eines Auftragsdatenverarbeiters.
                        <br />
                        <br />
                        Wenn Sie eingewilligt haben, von uns persönlich auf Sie zugeschnittene und mit Hilfe von
                        Profiling individualisierte Werbung zu erhalten, erhalten Sie von uns auch E-Mails mit Inhalten,
                        die folgende Verarbeitungen ermöglichen:
                        <br />
                        <br />
                        {'>'} Wenn Sie E-Mails von uns öffnen, enthaltene Links anklicken oder nach dem Klick auf einen
                        Link ein Webseiten-Formular absenden, wird dies festgestellt und diese Information gespeichert.
                        <br />
                        {'>'} Wenn Sie E-Mails von uns öffnen oder enthaltene Links anklicken, wird festgestellt und
                        gespeichert, welche Themen für Sie interessant sind.
                        <br />
                        {'>'} Wenn Sie Bilder in E-Mails abrufen oder Links anklicken, wird die Art des verwendeten
                        Endgerätes festgestellt und dies gespeichert.
                        <br />
                        {'>'} Wenn Sie Bilder in E-Mails abrufen oder Links anklicken, wird über eine Zuordnung Ihrer
                        IP-Adresse festgestellt, von welchem Ort der Abruf erfolgt und dies gespeichert. Die sich
                        hieraus ergebenden Informationen verwenden wir, um zu erfahren, welche Themen die Empfänger
                        unserer Newsletter besonders interessieren, um die Inhalte unserer Newsletter zukünftig
                        bedarfsgerechter zu gestalten.
                        <br />
                        <br />
                        <span className='blue'>10. Einwilligung in Werbung und Marktforschung</span>
                        <br />
                        <br />
                        Wenn Sie Informationen zu unseren Angeboten erhalten möchten, können Sie diese Werbung
                        bestellen. Hierfür werden im Rahmen der von Ihnen zu gebenden Einwilligung (Art. 6 Abs. 1 S. 1
                        lit. a DSGVO) folgende Daten zum Zwecke der Werbezustellung verarbeitet: Name, Vorname, E-Mail
                        Adresse und/oder Telefonnummer). Die Autostadt GmbH verwendet für die Einholung der Einwilligung
                        das sog. Double-Opt-In-Verfahren. Dabei wird Ihnen eine E-Mail an die angegebene E-Mail-Adresse
                        mit der Bitte um Bestätigung Ihrer Einwilligung gesendet. Wie Sie Ihre Einwilligung widerrufen
                        können, finden Sie im Abschnitt „Ihre Rechte“.
                        <br />
                        <br />
                        <span className='blue'>11. Nutzung von YouTube</span>
                        <br />
                        <br />
                        Unsere Seite verwendet für die Einbindung von Videos den Anbieter YouTube LLC , 901 Cherry
                        Avenue, San Bruno, CA 94066, USA, vertreten durch Google Inc., 1600 Amphitheatre Parkway,
                        Mountain View, CA 94043, USA. Normalerweise wird bereits bei Aufruf einer Seite mit
                        eingebetteten Videos Ihre IP-Adresse an YouTube gesendet und Cookies auf Ihrem Rechner
                        installiert. Wir haben unsere YouTube-Videos jedoch mit dem erweiterten Datenschutzmodus
                        eingebunden (in diesem Fall nimmt YouTube immer noch Kontakt zu dem Dienst DoubleClick von
                        Google auf, doch werden dabei laut der Datenschutzerklärung von Google personenbezogene Daten
                        nicht ausgewertet). Dadurch werden von YouTube keine Informationen über die Besucher mehr
                        gespeichert, es sei denn, sie sehen sich das Video an. Wenn Sie das Video anklicken, wird Ihre
                        IP-Adresse an YouTube übermittelt und YouTube erfährt, dass Sie das Video angesehen haben. Sind
                        Sie bei YouTube eingeloggt, wird diese Information auch Ihrem Benutzerkonto zugeordnet (dies
                        können Sie verhindern, indem Sie sich vor dem Aufrufen des Videos bei YouTube ausloggen).
                        <br />
                        <br />
                        Von der dann möglichen Erhebung und Verwendung Ihrer Daten durch YouTube haben wir keine
                        Kenntnis und darauf auch keinen Einfluss. Nähere Informationen können Sie der
                        Datenschutzerklärung von YouTube unter
                        <br />
                        <a href='https://google.de/intl/de/policies/privacy/'>
                            www.google.de/intl/de/policies/privacy/
                        </a>
                        <br />
                        entnehmen. Zudem verweisen wir für den generellen Umgang mit und die Deaktivierung von Cookies
                        auf unsere allgemeine Darstellung in dieser Datenschutzerklärung.
                        <br />
                        <br />
                        <span className='blue'>12. Einsatz von Cookies & Trackingtools</span>
                        <br />
                        <br />
                        a. Allgemeine Informationen Die Autostadt GmbH setzt auf ihren Webseiten verschiedene Cookies
                        ein. Cookies sind kleine Dateien mit Konfigurationsinformationen, die auf Ihrem Endgerät
                        gespeichert werden. Cookies lassen sich grundsätzlich in drei Kategorien unterteilen. Es gibt
                        Cookies, die für die Funktionalität der Webseite unerlässlich sind (sog. Funktionscookies),
                        Cookies, die den Komfort eines Webseitenbesuchs erhöhen und z. B. Ihre Spracheinstellungen
                        speichern (sog. Komfortcookies) und Cookies, aufgrund derer ein pseudonymisiertes Nutzungsprofil
                        erstellt wird (sog. Trackingcookies). Für diese Webseite sind lediglich Funktions- und
                        Trackingcookies vorgesehen. Die Verarbeitung der Funktionscookies ist notwendig, um Ihnen einen
                        Besuch der Webseite zu ermöglichen (vgl. Art. 6 Abs. 1 lit. b DSGVO). Trackingcookies werden nur
                        gesetzt, wenn eine Einwilligung durch den Webseitenbesucher dafür vorliegt (Art. 6 Abs. 1 lit. a
                        DSGVO). Die Einwilligung wird über den sog. Cookie-Banner abgegeben, der aktiv angeklickt werden
                        muss. Sie können die Einwilligung zur dargestellten Datenverarbeitung durch den Einsatz der
                        aufgeführten Cookies und Tracking Tools jederzeit mit Wirkung für die Zukunft widerrufen, indem
                        Sie auf den nachfolgenden Link klicken und in unserem Cookie Consent Manage
                        <br />
                        <br />
                        <a
                            onClick={() => {
                                setIFrameSrc('https://autostadt.de/footer/rechtliches/datenschutzerklärung');
                            }}
                        >
                            Meine Cookie Präferenzen verwalten
                        </a>
                        <br />
                        <br />
                        Weitere Informationen zu unseren Cookies entnehmen Sie bitte der Cookie-Richtlinie sowie dem
                        Folgenden.
                        <br />
                        <br />
                        b. Matomo Webtracking Die Webseiten der Autostadt GmbH verwenden Matomo, dabei handelt es sich
                        um einen sogenannten Webanalysedienst. Die Datenverarbeitung erfolgt auf Grundlage einer von
                        Ihnen abgegebenen Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Matomo verwendet sog. „Cookies”.
                        Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die unsererseits eine
                        Analyse der Benutzung der Webseite ermöglichen. Zu diesem Zweck werden die durch den Cookie
                        erzeugten Nutzungsinformationen an unseren Server übertragen und zu Nutzungsanalysezwecken
                        gespeichert. Die Auswertung der Cookies dient der Webseitenoptimierung. Ihre IP-Adresse wird bei
                        diesem Vorgang nur gekürzt übermittelt und dadurch anonymisiert, so dass Sie als Nutzer für uns
                        anonym bleiben. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite
                        werden nicht an Dritte weitergegeben. Sie können die Verwendung der Cookies durch eine
                        entsprechende Einstellung Ihrer Browser Software verhindern. Es kann jedoch sein, dass Sie in
                        diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Webseite vollumfänglich nutzen
                        können. Wenn Sie mit der Speicherung und Auswertung dieser Daten aus Ihrem Besuch nicht
                        einverstanden sind, dann können Sie der Speicherung und Nutzung nachfolgend per Mausklick
                        jederzeit widersprechen. In diesem Fall wird in Ihrem Browser ein sog. Opt-Out-Cookie abgelegt,
                        der zur Folge hat, dass Matomo keinerlei Sitzungsdaten erhebt. Achtung: Wenn Sie Ihre Cookies
                        löschen, so hat dies zur Folge, dass auch das Opt-Out-Cookie gelöscht wird und ggf. von Ihnen
                        erneut aktiviert werden muss.
                        <br />
                        <br />
                        <a
                            onClick={() => {
                                setIFrameSrc('https://autostadt.de/footer/rechtliches/datenschutzerklärung');
                            }}
                        >
                            Meine Cookie Präferenzen verwalten
                        </a>
                        <br />
                        <br />
                        <span className='upper'>
                            <b>C. DATENSICHERHEIT IM INTERNET</b>
                        </span>
                        <br />
                        <br />
                        Wir sind um alle notwendigen technischen und organisatorischen Sicherheitsmaßnahmen bemüht, um
                        Ihre personenbezogenen Daten so zu speichern, dass sie weder Dritten noch der Öffentlichkeit
                        zugänglich sind. Sollten Sie mit uns per E-Mail in Kontakt treten wollen, beachten Sie bitte,
                        dass bei diesem Kommunikationsweg die Vertraulichkeit der übermittelten Informationen nicht
                        vollständig gewährleistet werden kann. Wir empfehlen Ihnen daher, uns vertrauliche Informationen
                        ausschließlich über den Postweg zukommen zu lassen.
                        <br />
                        <br />
                        <span className='upper'>
                            <b>
                                D. ÜBERMITTELN WIR IHRE DATEN AN EMPFÄNGER AUSSERHALB DER EU ODER INTERNATIONALE
                                ORGANISATIONEN?
                            </b>
                        </span>
                        <br />
                        <br />
                        Im Rahmen der Nutzung von YouTube findet eine Übermittlung der personenbezogenen Daten an Google
                        LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, statt.
                        <br />
                        <br />
                        Ein Angemessenheitsbeschluss der Europäischen Kommission fehlt. Google LLC ist aber Mitglied im
                        EU-US Privacy Shield.
                        <br />
                        <br />
                        Weitere Informationen zum EU-US Privacy Shield finden Sie unter der URL:
                        <br />
                        <br />
                        <a href='https://www.privacyshield.gov'>https://www.privacyshield.gov</a>
                        <br />
                        <br />
                        <span className='upper'>
                            <b>E. WIE LANGE SPEICHERN WIR IHRE DATEN?</b>
                        </span>
                        <br />
                        <br />
                        Wir verarbeiten und speichern Ihre personenbezogenen Daten, solange es für die Erfüllung unserer
                        vertraglichen und gesetzlichen Pflichten erforderlich ist. Dabei ist zu beachten, dass unsere
                        Geschäftsbeziehung z.B. im Falle eines Jahreskartenabonnements ein Dauerschuldverhältnis ist,
                        welches auf Jahre angelegt ist.
                        <br />
                        <br />
                        Sind die Daten für die Erfüllung vertraglicher oder gesetzlicher Pflichten nicht mehr
                        erforderlich, werden diese regelmäßig gelöscht, es sei denn, deren - befristete -
                        Weiterverarbeitung ist erforderlich zu folgende Zwecken:
                        <br />
                        <br />
                        {'>'} Erfüllung handels- und steuerrechtlicher Aufbewahrungspflichten: Zu nennen sind das
                        Handelsgesetzbuch (HGB), die Abgabenordnung (AO), das Geldwäschegesetz (GwG). Die dort
                        vorgegebenen Fristen zur Aufbewahrung bzw. Dokumentation betragen zwei bis zehn Jahre.
                        <br />
                        {'>'} Erhaltung von Beweismitteln im Rahmen der gesetzlichen Verjährungsvorschriften. Nach den
                        §§ 195ff. des Bürgerlichen Gesetzbuches (BGB) können diese Verjährungsfristen bis zu 30 Jahre
                        betragen, wobei die regelmäßige Verjährungsfrist 3 Jahre beträgt.
                        <br />
                        {'>'} Erforderlich zur Erfüllung der aufgeführten Zwecke. In diesen Fällen können wir auch nach
                        Beendigung unserer Geschäftsbeziehung oder unseres vorvertraglichen Rechtsverhältnisses für eine
                        mit den Zwecken vereinbare Dauer Ihre Daten speichern und ggf. nutzen.
                        <br />
                        <br />
                        <span className='upper'>
                            <b>
                                F. SIND SIE GESETZLICH ODER VERTRAGLICH DAZU VERPFLICHTET, IHRE DATEN BEREITZUSTELLEN?
                            </b>
                        </span>
                        <br />
                        <br />
                        Welche Folgen kann es haben, wenn Sie die Daten nicht bereitstellen? Im Rahmen unserer
                        Geschäftsbeziehung müssen Sie diejenigen personenbezogenen Daten bereitstellen, die für die
                        Aufnahme und Durchführung einer Geschäftsbeziehung und der Erfüllung der damit verbundenen
                        vertraglichen Pflichten erforderlich sind oder zu deren Erhebung wir gesetzlich verpflichtet
                        sind. Ohne diese Daten werden wir in der Regel nicht in der Lage sein, den Vertrag mit Ihnen zu
                        schließen oder diesen auszuführen.
                        <br />
                        <br />
                        Insbesondere können wir nach den geldwäscherechtlichen Vorschriften verpflichtet sein, Sie vor
                        der Begründung der Geschäftsbeziehung anhand Ihres Ausweisdokumentes zu identifizieren und dabei
                        Namen, Geburtsort, Geburtsdatum, Staatsangehörigkeiten, Anschrift sowie Ausweisdaten zu erheben
                        und festzuhalten. Damit wir dieser gesetzlichen Verpflichtung nachkommen können, haben Sie uns
                        nach dem Geldwäschegesetz die notwendigen Informationen und Unterlagen zur Verfügung zu stellen
                        und sich im Laufe der Geschäftsbeziehung ergebende Änderungen unverzüglich anzuzeigen. Sollten
                        Sie uns die notwendigen Informationen und Unterlagen nicht zur Verfügung stellen, dürfen wir die
                        von Ihnen gewünschte Geschäftsbeziehung nicht aufnehmen oder fortsetzen.
                        <br />
                        <br />
                        Dies kann sich auch später im Rahmen der Geschäftsbeziehung erforderliche Daten beziehen. Sofern
                        wir darüber hinaus Daten von Ihnen erbitten, werden Sie über die Freiwilligkeit der Angaben
                        gesondert informiert.
                        <br />
                        <br />
                        <span className='upper'>
                            <b>G. IHRE RECHTE</b>
                        </span>
                        <br />
                        <br />
                        Ihre nachfolgenden Rechte können Sie gegenüber der Autostadt GmbH jederzeit unentgeltlich
                        geltend machen:
                        <br />
                        <br />
                        <strong>Auskunftsrecht:</strong> Sie haben das Recht, von uns Auskunft über die Verarbeitung
                        Ihrer personenbezogenen Daten zu erhalten.
                        <br />
                        <br />
                        <strong>Berichtigungsrecht:</strong> Sie haben das Recht, von uns die Berichtigung Sie
                        betreffender unrichtiger bzw. unvollständiger personenbezogener Daten zu verlangen.
                        <br />
                        <br />
                        <strong>Recht auf Löschung:</strong> Sie haben das Recht, bei Vorliegen der in Art. 17 DSGVO
                        genannten Voraussetzungen, die Löschung Ihrer Daten zu verlangen. Danach können Sie
                        beispielsweise die Löschung Ihrer Daten verlangen, soweit diese für die Zwecke, für die sie
                        erhoben wurden, nicht mehr notwendig sind. Außerdem können Sie Löschung verlangen, wenn wir Ihre
                        Daten auf der Grundlage Ihrer Einwilligung verarbeiten und Sie diese Einwilligung widerrufen.
                        Bitte beachten Sie, dass auch im Falle eines Widerrufs eine Verarbeitung Ihrer Daten auf einer
                        gesetzlichen Grundlage nicht ausgeschlossen ist und in diesem Fall eine Löschung möglicherweise
                        unterbleiben kann.
                        <br />
                        <br />
                        <strong>Recht auf Einschränkung der Verarbeitung:</strong> Sie haben das Recht, die
                        Einschränkung der Verarbeitung Ihrer Daten zu verlangen, wenn die Voraussetzungen des Art. 18
                        DSGVO vorliegen. Dies ist beispielsweise der Fall, wenn Sie die Richtigkeit Ihrer Daten
                        bestreiten. Für die Dauer der Überprüfung der Richtigkeit der Daten können Sie dann die
                        Einschränkung der Verarbeitung verlangen.
                        <br />
                        <br />
                        <strong>Widerspruchsrecht:</strong> Sofern die Verarbeitung auf einem überwiegenden Interesse
                        oder Ihre Daten zum Zwecke der Direktwerbung genutzt werden, haben Sie das Recht, der
                        Verarbeitung Ihrer Daten zu widersprechen. Ein Widerspruch ist zulässig, wenn die Verarbeitung
                        entweder im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt oder aufgrund
                        eines berechtigten Interesses der Autostadt GmbH oder eines Dritten erfolgt. Im Falle des
                        Widerspruchs bitten wir Sie, uns Ihre Gründe mitzuteilen, aus denen Sie der Datenverarbeitung
                        widersprechen. Daneben haben Sie das Recht, der Datenverarbeitung zu Zwecken der Direktwerbung
                        zu widersprechen. Das gilt auch für Profiling, soweit es mit der Direktwerbung zusammenhängt.
                        <br />
                        <br />
                        <strong>Recht auf Datenübertragbarkeit:</strong> Sofern die Datenverarbeitung auf der Grundlage
                        einer Einwilligung oder einer Vertragserfüllung beruht und diese zudem unter Einsatz einer
                        automatisierten Verarbeitung erfolgt, haben Sie das Recht, Ihre Daten in einem strukturierten,
                        gängigem und maschinenlesbaren Format zu erhalten und diese an einen anderen Datenverarbeiter zu
                        übermitteln.
                        <br />
                        <br />
                        <strong>Widerrufsrecht:</strong> Sofern die Datenverarbeitung auf einer Einwilligung beruht,
                        haben Sie das Recht, die Datenverarbeitung im Rahmen einer Einwilligung mit Wirkung für die
                        Zukunft jederzeit kostenlos widerrufen.
                        <br />
                        <br />
                        <strong>Beschwerderecht:</strong> Sie haben außerdem das Recht, sich bei einer Aufsichtsbehörde
                        (z. B. bei der Landesbeauftragten für den Datenschutz Niedersachsen) über unsere Verarbeitung
                        Ihrer Daten zu beschweren. Wir empfehlen allerdings, eine Beschwerde zunächst immer an unseren
                        Datenschutzbeauftragten zu richten.
                        <br />
                        <br />
                        <p className='blue'>Stand: Januar 2020</p>
                    </div>
                )}
                {props.content === CloseableEnum.Imprint && (
                    <div className='imprint'>
                        <h1 className='big-headline line upper'>Impressum</h1>
                        Transparenz und offene Kommunikation sind innerhalb und außerhalb der Autostadt GmbH für uns
                        selbstverständlich. Wichtige Informationen über die Autostadt GmbH und Kontaktmöglichkeiten
                        finden Sie auf dieser Seite.
                        <br />
                        <br />
                        Die Autostadt GmbH ist eine Tochtergesellschaft der Volkswagen AG mit Sitz in Wolfsburg.
                        <br />
                        <br />
                        Vorsitzender des Aufsichtsrats: Gunnar Kilian
                        <br />
                        Geschäftsführung: Armin Maus (Vorsitzender), Mandy Sobetzko, Marco Schubert
                        <br />
                        <br />
                        <b>
                            Postanschrift:
                            <br />
                            Autostadt GmbH
                            <br />
                            Stadtbrücke
                            <br />
                            38440 Wolfsburg
                            <br />
                            <br />
                            Telefon: <a href='tel:0800 288 678 238'>0800 288 678 238</a>
                            <br />
                            E-Mail: <a href='mailto:service@autostadt.de'>service@autostadt.de</a>
                        </b>
                        <br />
                        <br />
                        Die Autostadt GmbH ist im Handelsregister des Amtsgerichts Braunschweig unter der Nr. HRB 100419
                        eingetragen. Die Umsatzsteueridentifikationsnummer der Autostadt GmbH ist DE 204 908 992.
                        <br />
                        <br />
                        Die Autostadt GmbH ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle weder bereit noch dazu verpflichtet.
                        <br />
                        <br />
                        Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO: Plattform der EU zur außergerichtlichen
                        Online-Streitbeilegung:
                        <br />
                        <br />
                        <a href='https://ec.europa.eu/consumers/odr/'>https://ec.europa.eu/consumers/odr/.</a>
                        <br />
                        <br />
                        <br />
                        Stand: Mai 2021
                    </div>
                )}
                {props.content === CloseableEnum.Contact && (
                    <div className='contact'>
                        <div>
                            <h1 className='big-headline line upper'>KONTAKT</h1>
                            <p>
                                <b>Autostadt</b>
                                <br />
                                Stadtbrücke 38440
                                <br />
                                Wolfsburg
                                <br />
                                <br />
                                Ihre Fragen rund um die Fahrzeugabholung beantworten wir von Montag bis Freitag von
                                09:00 bis 18:00 Uhr und an Wochenenden und Feiertagen von 09:00 bis 16:00 Uhr unter:
                                <br />
                                <br />
                                <svg className='contact-icons'>
                                    <use xlinkHref='#telephone' />
                                </svg>
                                <a href='tel:0800 288 678 238'>0800 288 678 238</a>
                                <br />
                                <br />
                                <svg className='contact-icons'>
                                    <use xlinkHref='#email' />
                                </svg>
                                <a href='mailto:fahrzeugabholung@autostadt.de'>fahrzeugabholung@autostadt.de</a>
                            </p>
                        </div>
                        <div className='follow-us'>
                            <h2>FOLGEN SIE UNS</h2>
                            <div>
                                <a href='https://twitter.com/Autostadt'>
                                    <svg>
                                        <use xlinkHref='#twitter' />
                                    </svg>
                                </a>
                                <a href='https://www.instagram.com/autostadt'>
                                    <svg>
                                        <use xlinkHref='#insta' />
                                    </svg>
                                </a>
                                <a href='https://www.facebook.com/autostadt'>
                                    <svg>
                                        <use xlinkHref='#facebook' />
                                    </svg>
                                </a>
                                <a href='https://www.youtube.com/@autostadt'>
                                    <svg>
                                        <use xlinkHref='#youtube' />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                {props.content === CloseableEnum.Opening && (
                    <div className='opening'>
                        <h1 className='big-headline line upper'>ÖFFNUNGSZEITEN</h1>
                        <p>
                            <b>
                                Markenpavillons / Automuseum ZeitHaus / „moment! Marken & Erlebnis-Shop“ im ZeitHaus /
                                KonzernWelt mit Ausstellungen / Familienbereich MobiVersum / Elektrisierende
                                Probefahrten / Jahreskartenservice / Fahrzeugausstellung KundenCenter / Volkswagen
                                Zubehör Shop
                            </b>
                            <br />
                            Täglich von 10:00 bis 18:00 Uhr
                            <br />
                            <br />
                            <b>
                                Offroad-Trainings und
                                <br />
                                Fahrsicherheitsangebote
                            </b>
                            <br />
                            Täglich von 10:00 bis 18:00 Uhr (nach
                            <br /> vorheriger Buchung)
                            <br />
                            <br />
                            <b>SIM MOBILITY</b>
                            <br />
                            Täglich ab 10:30
                            <br />
                            <br />
                            <b>AbholerWelt (Anmeldung für Fahrzeugabholerinnen und -abholer)</b>
                            <br />
                            Montag bis Sonntag 08:30 bis 16:00 Uhr
                            <br />
                            <br />
                            <b>Restaurants</b>
                            <br />
                            {'Die Öffnungszeiten unserer Restaurants finden Sie auf der jeweiligen '}
                            <a
                                className='under normal'
                                href='https://autostadt.de/restaurants'
                                rel='noreferrer'
                                target='_blank'
                            >
                                Restaurantseite
                            </a>
                            .
                        </p>
                        <br />
                        <p>
                            Auch nachdem unsere Ausstellungen, das ZeitHaus Museum, der Shop und die Pavillons um 18:00
                            Uhr geschlossen haben, können Sie abends unsere Restaurants besuchen oder durch die
                            Parklandschaft spazieren.
                        </p>
                        <p className='italic'>
                            <br />
                            Änderungen vorbehalten.
                        </p>
                    </div>
                )}
            </Page>
        </>
    );
}
