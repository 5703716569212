import html2pdf from 'html2pdf.js';

export function generateChecklistPdf(items) {
    const page = document.getElementById('page');
    var opt = {
        margin: [0, 0, -0.02, 0], // avoid overflow into next page
        filename: 'Checkliste.pdf',
        image: { type: 'jpeg', quality: 1 },
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf().from(page).set(opt).save();
}
