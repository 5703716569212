import './interfaces';
import { AProps } from './interfaces';

function A(props: AProps) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 254.808 378' className={props.name}>
            <defs>
                <linearGradient id='linear-gradient-A' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
                    <>
                        <stop offset='0' stopColor={props.firstColor ? props.firstColor : '#5A2C7F'} />
                        <stop offset='1' stopColor={props.secondColor ? props.secondColor : '#5DC385'} />
                    </>
                </linearGradient>
            </defs>
            <path
                d='M234.862,60.2H156.946L68.5,438.2h77.916L160.1,373.972h71.6L245.391,438.2h77.916ZM170.844,316.271,195.9,158.333l25.06,157.939Z'
                transform='translate(-68.5 -60.2)'
                fill={props.fill} // 'url(#linear-gradient-A)'
            />
        </svg>
    );
}

export default A;
