import React from 'react';
import './styles.scss';
import { IndicatorsProps } from './interfaces';

export default function Indicators(props: IndicatorsProps): JSX.Element {
    const array = Array.from(Array(props.length).keys());

    return (
        <div className='indicator-container'>
            <div className='indicators'>
                {array.map(i => (
                    <div
                        key={`indicator-${i}`}
                        className={`indicator
                        ${i + 1 === props.active ? ' active-indicator' : ''}
                        ${props.color ? ` ${props.color}` : ''}`}
                        onClick={() => props.setActive && props.setActive(i)}
                    />
                ))}
            </div>
        </div>
    );
}
