import { Link } from 'react-router-dom';

export default function CustomLink(props: { children: React.ReactNode; to: string }): JSX.Element {
    const [link, hash] = props.to.split('#');
    const scrollOffset = 91;

    return (
        <Link
            onClick={() => {
                history.replaceState(
                    '',
                    document.title,
                    location.pathname + location.search + (hash ? `#${hash}` : '')
                );

                setTimeout(() => {
                    const targetElement = document.getElementById(hash);
                    if (targetElement) {
                        const scrollableContainer = document.querySelector('.page-content') as HTMLElement;

                        if (scrollableContainer) {
                            const elementPosition = targetElement.offsetTop - scrollableContainer.offsetTop;

                            scrollableContainer.scrollTo({
                                top: elementPosition - scrollOffset,
                                behavior: 'smooth',
                            });
                        }
                    }
                }, 100);
            }}
            to={`${link}${hash !== undefined ? `#${hash}` : ''}`}
        >
            {props.children}
        </Link>
    );
}
