const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true
};

export default sliderSettings;
