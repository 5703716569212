import React from 'react';
import Page from '../Page';
import { PageChildrenEnum } from '../../enums';
import A from '../AutostadtA';
import './styles.scss';

export default function Checkin(): JSX.Element {
    return (
        <Page child={PageChildrenEnum.CheckIn}>
            <div className='checkin'>
                <div className='logo'>
                    <A name='purpleA' fill='url(#linear-gradient-A)' firstColor='#C52A87' secondColor='#632483' />
                    <svg className='autostadt'>
                        <use xlinkHref='#autostadt' />
                    </svg>
                </div>
                <h1>Online Check-in</h1>
                <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua.
                    <br />
                    <br />
                    At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
                    sanctus est Lorem ipsum dolor sit amet.
                    <br />
                    <br />
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua.
                </p>
                <button>LOS GEHT'S</button>
            </div>
        </Page>
    );
}
