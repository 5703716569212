import React, { useContext } from 'react';
import SspProviderContext from '../../utils/SspProvider/context';

export default function Loading(): JSX.Element {
    const store = useContext(SspProviderContext);

    const line = <div className='overlay-line' />;

    return (
        <div className='overlay-container blue'>
            <div className='overlay full white'>
                <div className='overlay-content'>
                    <h1>Ihre AbholerApp wird geladen</h1>
                    {line}
                    {store.state.loadingProgress > 0 && <p>{`${Math.round(store.state.loadingProgress * 100)}%`}</p>}
                    <div className='progress-bar'>
                        {store.state.loadingProgress > 0 ? (
                            <div className='progress' style={{ width: `${store.state.loadingProgress * 100}%` }} />
                        ) : (
                            <div className='loading-zero' />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
