import React from 'react';
import Page from '../Page';
import { PageChildrenEnum } from '../../enums';
import { useParams } from 'react-router-dom';

export default function IFrame(): JSX.Element {
    const { url } = useParams();

    return (
        <Page child={PageChildrenEnum.None} iframeSrc={'https://' + url}>
            <p>Loading...</p>
        </Page>
    );
}
