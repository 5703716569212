import SSPContentFieldAccessor from 'sspcontentext/dist/model/accessor/SSPContentFieldAccessor';

export function getHighlights(contentModel: SSPContentFieldAccessor) {
    const standardPackagesWithImage = contentModel
        .reflist('serie_equip')
        .filter(equipItem => equipItem.asModel.has('assets') && equipItem.asModel.has('packageItems'));
    const standardPackagesWithoutImage = contentModel
        .reflist('serie_equip')
        .filter(equipItem => !equipItem.asModel.has('assets') && equipItem.asModel.has('packageItems'));
    const specialPackagesWithImage = contentModel
        .reflist('special_equip')
        .filter(equipItem => equipItem.asModel.has('assets') && equipItem.asModel.has('packageItems'));
    const specialPackagesWithoutImage = contentModel
        .reflist('special_equip')
        .filter(equipItem => !equipItem.asModel.has('assets') && equipItem.asModel.has('packageItems'));

    const highlightEquipments = contentModel.reflist('highlight_equip');
    const highlights = [
        ...highlightEquipments,
        ...specialPackagesWithImage,
        ...standardPackagesWithImage,
        ...specialPackagesWithoutImage,
        ...standardPackagesWithoutImage,
    ];

    return [...new Set(highlights)].slice(0, 10);
}
