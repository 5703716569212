import React, { useRef, useState } from 'react';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import './styles.scss';
import { MapContainerProps } from './interfaces';
import Lageplan from '../../assets/images/Lageplan_tasa.svg';
import AnfahrtA from '../../assets/images/AnfahrtA.svg';
import { mapPointsMap, mapPointsTravel } from '../Map/mapPoints';

let timer: number | undefined;
export default function MapContainer(props: MapContainerProps) {
    const factor = 8;
    const mapImg = props.travel ? AnfahrtA : Lageplan;
    const aspectRatioImg = props.travel ? 106 / 75 : 37 / 25; // change if image is changed!!!
    const mapHeight = props.heightNormal * factor;
    const mapWidth = mapHeight * aspectRatioImg;
    const containerHeight = props.fullscreen ? props.heightFullscreen : props.heightNormal;
    const containerWidth = props.width;
    const initScale = 1 / factor;
    const [currentState, setCurrentState] = useState<{ scale: number; positionX: number; positionY: number }>({
        scale: initScale,
        positionX: -(mapWidth * initScale - containerWidth) / 2,
        positionY: -(mapHeight * initScale - containerHeight) / 2,
    });
    const wrapperRef = useRef<ReactZoomPanPinchRef | null>(null);
    const maxX = mapWidth * currentState.scale - containerWidth;
    const maxY = mapHeight * currentState.scale - containerHeight;

    function limit(min: number, x: number, max: number) {
        return Math.max(Math.min(min, x), max);
    }

    function repeat(d: { x: number; y: number }) {
        if (wrapperRef.current) {
            wrapperRef.current.setTransform(
                limit(0, currentState.positionX - 5 * d.x, -maxX),
                limit(0, currentState.positionY - 5 * d.y, -maxY),
                currentState.scale,
                20,
                'linear'
            );
        }
        window.clearTimeout(timer);
        timer = window.setTimeout(
            () =>
                repeat({
                    x: d.x + d.x / Math.abs(d.x || 1),
                    y: d.y + d.y / Math.abs(d.y || 1),
                }),
            20
        );
    }

    return (
        <div className='map-container' style={{ height: containerHeight, width: containerWidth }}>
            <TransformWrapper
                ref={wrapperRef}
                minScale={1 / factor}
                maxScale={8 / factor}
                initialScale={initScale}
                initialPositionX={currentState.positionX}
                initialPositionY={currentState.positionY}
                onTransformed={(_, state) => setCurrentState(state)}
            >
                <TransformComponent
                    wrapperClass={'map-transform-wrapper'}
                    wrapperStyle={{ height: containerHeight, width: containerWidth }}
                    contentStyle={{
                        height: mapHeight,
                        width: mapWidth,
                    }}
                >
                    <img src={mapImg} alt={'autostadt-map'} />
                    {(props.travel ? mapPointsTravel : mapPointsMap).map((point, index) => (
                        <div
                            key={`map-point-${index}`}
                            className='map-point'
                            style={point.position}
                            onClick={() => props.setMapPointIndex(index)}
                        />
                    ))}
                </TransformComponent>
            </TransformWrapper>
            <button
                className='map-btn btn-up'
                onTouchStart={() => repeat({ x: 0, y: -1 })}
                onTouchEnd={() => window.clearTimeout(timer)}
                onPointerLeave={() => window.clearTimeout(timer)}
                disabled={currentState.positionY > -1}
            >
                <svg>
                    <use xlinkHref='#chevron-right' />
                </svg>
            </button>
            <button
                className='map-btn btn-down'
                onTouchStart={() => repeat({ x: 0, y: 1 })}
                onTouchEnd={() => window.clearTimeout(timer)}
                onPointerLeave={() => window.clearTimeout(timer)}
                disabled={currentState.positionY < -maxY + 1}
            >
                <svg>
                    <use xlinkHref='#chevron-right' />
                </svg>
            </button>
            <button
                className='map-btn btn-left'
                onTouchStart={() => repeat({ x: -1, y: 0 })}
                onTouchEnd={() => window.clearTimeout(timer)}
                onPointerLeave={() => window.clearTimeout(timer)}
                disabled={currentState.positionX > -1}
            >
                <svg>
                    <use xlinkHref='#chevron-right' />
                </svg>
            </button>
            <button
                className='map-btn btn-right'
                onTouchStart={() => repeat({ x: 1, y: 0 })}
                onTouchEnd={() => window.clearTimeout(timer)}
                onPointerLeave={() => window.clearTimeout(timer)}
                disabled={currentState.positionX < -maxX + 1}
            >
                <svg>
                    <use xlinkHref='#chevron-right' />
                </svg>
            </button>
            <button className='btn-fullscreen' onClick={() => props.setFullscreen(!props.fullscreen)}>
                {props.fullscreen ? (
                    <svg>
                        <use xlinkHref='#fullscreen-close' />
                    </svg>
                ) : (
                    <svg>
                        <use xlinkHref='#fullscreen-open' />
                    </svg>
                )}
            </button>
        </div>
    );
}
