import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const width = window.innerWidth;
const height = window.innerHeight;

if (width > height) {
    const url = new URL(window.location.href);
    root.render(<iframe className='app' title='DID' src={url.toString()} />);
} else {
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}

serviceWorkerRegistration.register();
