import React from 'react';
import AutostadtAImage from '../../assets/images/Autostadt.jpg';

export default function Preview(props: { items: Array<unknown> }) {
    const styles = {
        page: {
            width: '21cm',
            height: '29.7cm',
            backgroundColor: 'white',
            padding: '75px 85px',
            fontFamily: 'AgendaCondensed',
            position: 'relative' as 'relative',
        },
        autostadtAImage: {
            position: 'absolute' as 'absolute',
            height: 100,
            top: 75,
            right: 80,
        },
        h1: {
            fontSize: 36,
            marginBottom: 80,
            letterSpacing: 0.5,
        },
        h2: {
            fontSize: 24,
            marginBottom: 32,
            letterSpacing: 0.5,
        },
        row: {
            width: '100%',
            paddingLeft: 40,
            paddingRight: 40 + 18 + 20,
            paddingTop: 10,
            paddingBottom: 10,
            position: 'relative' as 'relative',
            fontSize: 18,
            letterSpacing: 0.4,
            fontWeight: 'bold',
        },
        checkbox: {
            width: 22,
            height: 22,
            border: 'solid 3px black',
            position: 'absolute' as 'absolute',
            bottom: 9,
            right: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        checkmark: {
            color: 'black',
            fontSize: 21,
            marginBottom: 5,
        },
    };

    return (
        <div className='preview'>
            <div id='page' style={styles.page}>
                <img src={AutostadtAImage} style={styles.autostadtAImage} alt='' />
                <h1 style={styles.h1}>CHECKLISTE</h1>
                <h2 style={styles.h2}>Was Sie unbedingt zur Abholung mitbringen müssen:</h2>
                {props.items &&
                    props.items.map((item: any, index: number) => (
                        <div key={`checklist-row-${index}`} style={styles.row}>
                            <p>{item.name}</p>
                            <div style={styles.checkbox}>
                                {localStorage.checkedItems && localStorage.checkedItems.includes(item.id) && (
                                    <span style={styles.checkmark}>✓</span>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}
