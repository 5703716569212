import SSPContentExt from 'sspcontentext';
import React, { useCallback, useEffect, useReducer } from 'react';
import Context from './context';
import { INITIAL_STATE, reducer, SET_CONTENT_ACTION, SET_LOADING_PROGRESS_ACTION, SET_OVERLAY_ACTION } from './store';
import { OverlayEnum } from '../../enums';

let initialPageLoad = false;

const SspContextProvider = (props: { children: React.ReactNode }): JSX.Element => {
    /**
     * state for global ssp context
     * provider
     */
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    const url = import.meta.env.VITE_REACT_APP_SSP_URL as string;
    const vin = new URLSearchParams(location.search).get('vin');

    const getJSON = useCallback(() => {
        return new Promise(function (resolve, reject) {
            const xml = new XMLHttpRequest();
            xml.open('GET', `${url}/module/didKansas/bpca/pricesheetByVin/${vin}/allcontents.json`, true);
            xml.responseType = 'text';
            xml.onprogress = function (prog) {
                if (prog.lengthComputable) {
                    dispatch({
                        type: SET_LOADING_PROGRESS_ACTION,
                        value: prog.total > 0 ? prog.loaded / prog.total : 0,
                    });
                }
            };
            xml.onloadend = function () {
                dispatch({ type: SET_LOADING_PROGRESS_ACTION, value: 1 });
            };
            xml.onload = function () {
                if (xml.status === 200) {
                    try {
                        const respJson = JSON.parse(xml.responseText);
                        dispatch({ type: SET_OVERLAY_ACTION, value: null });
                        if (
                            localStorage.getItem('popupShown') === null &&
                            !window.matchMedia('(display-mode: standalone)').matches // not already PWA
                        ) {
                            setTimeout(() => {
                                dispatch({ type: SET_OVERLAY_ACTION, value: { enum: OverlayEnum.HomeScreen } });
                            }, 60000);
                        }
                        resolve(respJson);
                    } catch (e: any) {
                        dispatch({ type: SET_OVERLAY_ACTION, value: { enum: OverlayEnum.WrongVIN } });
                        reject({
                            status: xml.status,
                            statusText: e.message,
                        });
                    }
                } else {
                    dispatch({ type: SET_OVERLAY_ACTION, value: { enum: OverlayEnum.WrongVIN } });
                    reject({
                        status: xml.status,
                        statusText: xml.statusText,
                    });
                }
            };
            xml.onerror = function () {
                dispatch({ type: SET_OVERLAY_ACTION, value: { enum: OverlayEnum.WrongVIN } });
                reject({
                    status: xml.status,
                    statusText: xml.statusText,
                });
            };
            xml.send();
            dispatch({ type: SET_LOADING_PROGRESS_ACTION, value: 0 });
        });
    }, [url, vin]);

    /**
     * inits ssp to load data
     * from the server
     *
     * @param lang
     * @param device
     */
    const initSSP = useCallback(async () => {
        try {
            /**
             * create class that handles the SSPContentExt
             */
            const ext = SSPContentExt.instance;

            const sspWeb = {
                ext,
                init: async () => {
                    ext.configure(url);
                    const json = (await getJSON()) as Array<object>;
                    ext.initWithModels(json);
                },
            };

            await sspWeb.init();
            return sspWeb;
        } catch (error) {
            console.error("Couldn't initialize SSP", error);
            throw new Error(`${error}`);
        }
    }, [getJSON, url]);

    /**
     * retrieves the initial content from ssp
     */
    const handleInitialPageLoad = useCallback(async () => {
        try {
            if (!vin) {
                dispatch({ type: SET_OVERLAY_ACTION, value: { enum: OverlayEnum.WrongVIN } });
                return;
            }
            const sspExt = await initSSP();

            /**
             * load data from ssp and pass it to
             * the ssp provider store
             */
            if (sspExt) {
                const startContent = sspExt.ext.startModel;
                dispatch({ type: SET_CONTENT_ACTION, value: startContent });
            }
        } catch (error) {
            console.error(error);
        }
    }, [initSSP, vin]);

    useEffect(() => {
        if (!initialPageLoad) {
            initialPageLoad = true;
            handleInitialPageLoad();
        }
    }, [handleInitialPageLoad]);

    const getContextValue = useCallback(
        () => ({
            state,
            dispatch,
        }),
        [state, dispatch]
    );

    return <Context.Provider value={getContextValue()}>{props?.children}</Context.Provider>;
};

export default SspContextProvider;
