import React, { useContext } from 'react';
import './styles.scss';
import Page from '../Page';
import { PageChildrenEnum } from '../../enums';
import { EditorialContentProps } from '../Page/interfaces';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import SspProviderContext from '../../utils/SspProvider/context';
import i18next from 'i18next';
import InfoBoxBlue from './Sections/InfoBoxBlue';
import FunAndFacts from './Sections/FunAndFacts';
import SliderPreviewTile from '../PreviewTile/SliderPreviewTile';
import SortTiles from '../../utils/SortTiles';
import StoryImg from '../../../public/story.png';

function Story(props: EditorialContentProps) {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const storyItems = contentModel.reflist('StoryItems');
    const productionAndLogistics: SSPContentVO[] = storyItems.filter(
        item => item.asModel.target.Kategorie === 'products_logistics'
    );
    const traditionAndMilestones: SSPContentVO[] = storyItems.filter(
        item => item.asModel.target.Kategorie === 'tradition_milestones'
    );
    const technologyAndInnovation: SSPContentVO[] = storyItems.filter(
        item => item.asModel.target.Kategorie === 'technology_innovations'
    );
    const sustainabilityAndSocial: SSPContentVO[] = storyItems.filter(
        item => item.asModel.target.Kategorie === 'sustainability_social'
    );
    const vehicleInAutostadt: SSPContentVO[] = storyItems.filter(
        item => item.asModel.target.Kategorie === 'vehicle_Autostadt'
    );
    const vehicleTypeId = contentModel.ref('engine').asModel.str('engine_type') === 'Electric' && contentModel.str('title').includes('ID.');


    SortTiles(productionAndLogistics);
    SortTiles(traditionAndMilestones);
    SortTiles(technologyAndInnovation);
    SortTiles(sustainabilityAndSocial);

    return (
        <Page child={PageChildrenEnum.Story} editorialContent={props.editorialContent}>
            <div id='story'>
                <div className='story-header-img'>
                    {' '}
                    <img src={StoryImg} alt='story-header' />
                </div>
                <div className='content'>
                    <h1>{i18next.t('Story')}</h1>
                    <p>{i18next.t('StoryOverviewText')}</p>
                    {productionAndLogistics && productionAndLogistics.length !== 0 && (
                        <div id='production-and-logistics'>
                            <SliderPreviewTile
                                id='production-and-logistics'
                                titleKey='ProductionAndLogistics'
                                items={productionAndLogistics}
                            />
                        </div>
                    )}
                    {traditionAndMilestones && traditionAndMilestones.length !== 0 && (
                        <div id='tradition-and-milestones'>
                            <SliderPreviewTile
                                id='tradition-and-milestones'
                                titleKey='TraditionAndMilestones'
                                items={traditionAndMilestones}
                            />
                        </div>
                    )}

                    {technologyAndInnovation && technologyAndInnovation.length !== 0 && (
                        <div id='technology-and-innovation'>
                            <SliderPreviewTile
                                id='technology-and-innovation'
                                titleKey='TechnologyAndInnovation'
                                items={technologyAndInnovation}
                            />
                        </div>
                    )}

                    {vehicleInAutostadt && vehicleInAutostadt.length !== 0 && (
                        <div id='vehicle-in-autostadt'>
                            <SliderPreviewTile
                                id='vehicle-in-autostadt'
                                titleKey='VehicleInAutostadt'
                                items={vehicleInAutostadt}
                            />
                        </div>
                    )}

                    {vehicleTypeId && <InfoBoxBlue />}
                    {sustainabilityAndSocial && sustainabilityAndSocial.length !== 0 && (
                        <div id='sustainability-and-social'>
                            <SliderPreviewTile
                                id='sustainability-and-social'
                                titleKey='SustainabilityAndSocial'
                                items={sustainabilityAndSocial}
                            />
                        </div>
                    )}
                </div>
                <FunAndFacts />
            </div>
        </Page>
    );
}

export default Story;
