import React, { useContext, useRef, useState } from 'react';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import SspProviderContext from '../../../utils/SspProvider/context';
import { EditorialContentProps } from '../../Page/interfaces';
import { CarDetailEnum } from '../../../enums';
import i18next from 'i18next';
import CarDetails from '../../Overlay/CarDetails';

export default function Details(props: EditorialContentProps): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const standardEquipList = contentModel.reflist('serie_equip');
    const optionalEquipList = contentModel.reflist('special_equip');
    const highlightEquipList = contentModel.reflist('highlight_equip');
    const engine = contentModel.ref('engine').asModel;
    const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

    const overviewRef = useRef<HTMLDivElement>(null);
    const highlightRef = useRef<HTMLDivElement>(null);
    const optionalRef = useRef<HTMLDivElement>(null);
    const standardRef = useRef<HTMLDivElement>(null);

    const standardCategories = [
        ...new Set(standardEquipList.map(standardEquipItem => standardEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');

    const optionalCategories = [
        ...new Set(optionalEquipList.map(optionalEquipItem => optionalEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');

    const highlightCategories = [
        ...new Set(highlightEquipList.map(highlightEquipItem => highlightEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');

    const scrollToRef = () => {
        const details = document.getElementById('details');
        if (details) details.scrollIntoView();
    };

    const toggleDropdown = (category: string, ref: React.RefObject<HTMLDivElement>) => {
        if (activeDropdown === category) {
            setActiveDropdown(null);
            scrollToRef();
        } else {
            setActiveDropdown(category);
        }
    };

    const showOverview =
        engine.has('fuel_power') ||
        engine.str('engine_type') === 'Electric' ||
        engine.has('motor_gear') ||
        engine.has('motor_drive') ||
        (engine.str('engine_type') === 'Electric' && engine.has('fuel2')) ||
        (engine.str('engine_type') !== 'Electric' && engine.has('fuel_type'));

    return (
        <div id='details'>
            <h2>{i18next.t('Details')}</h2>
            <div className='details-options'>
                {showOverview && (
                    <div
                        ref={overviewRef}
                        className={`details-headline ${activeDropdown === 'Overview' ? 'opened' : 'closed'}`}
                        onClick={() => toggleDropdown('Overview', overviewRef)}
                    >
                        <h5>{i18next.t('Overview')}</h5>
                        <button className='td-button'>
                            <svg>
                                <use xlinkHref='#chevron-narrow-right' />
                            </svg>
                        </button>
                    </div>
                )}
                {showOverview && activeDropdown === 'Overview' && (
                    <div className='dropdown-body'>
                        <div className='car-details'>
                            <CarDetails CarEnum={CarDetailEnum.Overview} />
                        </div>
                    </div>
                )}

                {!props.editorialContent && highlightCategories.length > 0 && (
                    <div
                        ref={highlightRef}
                        className={`details-headline ${activeDropdown === 'Highlights' ? 'opened' : 'closed'}`}
                        onClick={() => toggleDropdown('Highlights', highlightRef)}
                    >
                        <h5>{i18next.t('EquipmentHighlights')}</h5>
                        <button className='td-button'>
                            <svg>
                                <use xlinkHref='#chevron-narrow-right' />
                            </svg>
                        </button>
                    </div>
                )}
                {!props.editorialContent && highlightCategories.length > 0 && activeDropdown === 'Highlights' && (
                    <div className='dropdown-body'>
                        <div className='car-details'>
                            <CarDetails CarEnum={CarDetailEnum.Highlight} />
                        </div>
                    </div>
                )}

                {optionalCategories.length > 0 && (
                    <div
                        ref={optionalRef}
                        className={`details-headline ${activeDropdown === 'SpecialEquipment' ? 'opened' : 'closed'}`}
                        onClick={() => toggleDropdown('SpecialEquipment', optionalRef)}
                    >
                        <h5>{i18next.t('SpecialEquipment')}</h5>
                        <button className='td-button'>
                            <svg>
                                <use xlinkHref='#chevron-narrow-right' />
                            </svg>
                        </button>
                    </div>
                )}
                {optionalCategories.length > 0 && activeDropdown === 'SpecialEquipment' && (
                    <div className='dropdown-body'>
                        <div className='car-details'>
                            <CarDetails CarEnum={CarDetailEnum.Optional} />
                        </div>
                    </div>
                )}

                {standardCategories.length > 0 && (
                    <div
                        ref={standardRef}
                        className={`details-headline ${activeDropdown === 'StandardEquipment' ? 'opened' : 'closed'}`}
                        onClick={() => toggleDropdown('StandardEquipment', standardRef)}
                    >
                        <h5>{i18next.t('StandardEquipment')}</h5>
                        <button className='td-button'>
                            <svg>
                                <use xlinkHref='#chevron-narrow-right' />
                            </svg>
                        </button>
                    </div>
                )}
                {standardCategories.length > 0 && activeDropdown === 'StandardEquipment' && (
                    <div className='dropdown-body'>
                        <div className='car-details'>
                            <CarDetails CarEnum={CarDetailEnum.Standard} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
