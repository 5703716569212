import PreviewTile from '../../PreviewTile';
import './funandfacts.scss';
import i18next from 'i18next';
import React, { useContext } from 'react';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import SortTiles from '../../../utils/SortTiles';
import SspProviderContext from '../../../utils/SspProvider/context';

function FunAndFacts() {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const funAndFacts: SSPContentVO[] = contentModel
        .reflist('StoryItems')
        .filter(item => item.asModel.target.Kategorie === 'fun_facts');

    SortTiles(funAndFacts);

    return (
        <div id='fun-and-facts'>
            {funAndFacts.length > 0 && <h2>{i18next.t('FunAndFacts')}</h2>}
            {funAndFacts.map((fact, index) => (
                <PreviewTile
                    key={`fun-facts- ${index}`}
                    tileType={'medium'}
                    h5={fact.asModel.str('Name')}
                    p={fact.asModel.str('DescriptionUpper')}
                    img={
                        fact.asModel.reflist('Assets')[0].asAsset.fileFamily === 'video'
                            ? fact.asModel.reflist('Assets')[0].asAsset.posterUrl
                            : fact.asModel.reflist('Assets')[0].asAsset.url
                    }
                    url={fact.asModel.str('Url')}
                    funfact={fact}
                />
            ))}
        </div>
    );
}

export default FunAndFacts;
