import React from 'react';

export default function Contact(): JSX.Element {
    return (
        <div id='contact'>
            <div className='blue-heading'>
                <h1 className='big-headline line center'>NOCH ETWAS AUF DEM HERZEN?</h1>
                <h2 className='middle-headline center'>KONTAKTIEREN SIE UNS JEDERZEIT</h2>
            </div>
            <svg className={'contact-bigsvg'}>
                <use xlinkHref='#contactSVG' />
            </svg>
            <div className={'contact-text'}>
                <p>
                    Ihre Fragen rund um die Fahrzeugabholung beantworten wir von Montag bis Freitag von 09:00 bis 18:00
                    Uhr und an Wochenenden und Feiertagen von 09:00 bis 16:00 Uhr unter:
                </p>

                <br />
                <div className={'contact-icontextwrapper contact-info'}>
                    <svg className={'contact-icons'}>
                        <use xlinkHref='#telephone' />
                    </svg>
                    <a href='tel:0800 288 678 238'>0800 288 678 238</a>
                </div>
                <br />
                <div className={'contact-icontextwrapper contact-info'}>
                    <svg className={'contact-icons'}>
                        <use xlinkHref='#email' />
                    </svg>
                    <a href='mailto:fahrzeugabholung@autostadt.de'>fahrzeugabholung@autostadt.de</a>
                </div>
            </div>
        </div>
    );
}
