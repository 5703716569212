import React, { useContext, useEffect, useRef, useState } from 'react';
import './styles.scss';
import ReactSlider from 'react-slider';
import SspProviderContext from '../../utils/SspProvider/context';

let controlsTimeout: NodeJS.Timeout | undefined;

let activeVideoId: string | null = null;

export default function VideoPlayer(props: {
    link: string;
    filetype: string;
    placeholder?: string;
    autoPlay?: boolean;
    loopVideo?: boolean;
    hideControl?: boolean;
    videoId: string;
}) {
    const store = useContext(SspProviderContext);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [playing, setPlaying] = useState<boolean>(false);
    const [muted, setMuted] = useState<boolean>(true);
    const [volume, setVolume] = useState<number>(100);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const controlsRef = useRef<HTMLDivElement>(null);
    const [controlsVisible, setControlsVisible] = useState(true);
    const [showVolumeBar, setShowVolumeBar] = useState(false);
    const [loopVideo, setLoopVideo] = useState(false);

    function activateFullScreen(videoContainer: any) {
        if (videoContainer?.requestFullscreen) {
            videoContainer.requestFullscreen();
            setIsFullscreen(true);
        } else if (videoContainer?.webkitRequestFullscreen) {
            // Safari
            videoContainer.webkitRequestFullscreen();
            setIsFullscreen(true);
        } else if ((videoRef.current as any).webkitEnterFullScreen) {
            // IOS Mobile edge case
            (videoRef.current as any).webkitEnterFullScreen();
        }
    }

    function deactivateFullScreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if ((document as any).webkitExitFullscreen) {
            // Safari
            (document as any).webkitExitFullscreen();
        } else if ((videoRef.current as any).webkitExitFullScreen) {
            // IOS Mobile edge case
            (videoRef.current as any).webkitExitFullScreen();
        }
        setIsFullscreen(false);
        const largeElement = document.querySelector('.large') as HTMLElement;
        if (largeElement) {
            const largeHeight = largeElement.offsetHeight;

            const slickList = largeElement.closest('.slick-list') as HTMLElement;
            if (slickList) {
                slickList.style.height = `${largeHeight}px`;
            }
        }
    }

    useEffect(() => {
        const handleOrientationChange = () => {
            const videoElement = videoRef?.current && (videoRef.current.parentElement as any);

            if (store.state.videoState && activeVideoId === props.videoId) {
                if (
                    (window.screen.orientation.type.includes('landscape') ||
                        window.screen.orientation.angle === 90 ||
                        window.screen.orientation.angle === -90) &&
                    playing
                ) {
                    if (!isFullscreen) {
                        activateFullScreen(videoElement);
                    }
                } else {
                    deactivateFullScreen();
                }
            } else {
                deactivateFullScreen();
            }
        };

        window.addEventListener('orientationchange', handleOrientationChange, true);
        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange, true);
        };
    }, [playing, activeVideoId, isFullscreen]);

    const resetHideControlsTimer = () => {
        clearTimeout(controlsTimeout);
        controlsTimeout = setTimeout(() => {
            setControlsVisible(false);
        }, 5000);
        setShowVolumeBar(false);
    };

    useEffect(() => {
        if (props.loopVideo) {
            setLoopVideo(true);
        }
        if (props.hideControl) {
            setControlsVisible(false);
        }

        const handleMouseMove = () => {
            if (props.hideControl) return;
            setControlsVisible(true);
            resetHideControlsTimer();
        };

        const handleTouchStart = () => {
            if (props.hideControl) return;
            setControlsVisible(true);
            resetHideControlsTimer();
        };

        if (videoRef.current) {
            videoRef.current.addEventListener('mousemove', handleMouseMove);
            videoRef.current.addEventListener('touchstart', handleTouchStart);
        }
        resetHideControlsTimer();

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('mousemove', handleMouseMove);
                videoRef.current.removeEventListener('touchstart', handleTouchStart);
            }
            clearTimeout(controlsTimeout);
        };
    }, []);

    useEffect(() => {
        if (props.autoPlay && videoRef.current && store.state.videoState === props.videoId) {
            videoRef.current.play();
            setPlaying(true);
            activeVideoId = props.videoId;
            clearTimeout(controlsTimeout);
            setControlsVisible(true);
            resetHideControlsTimer();
            setShowVolumeBar(false);
        } else {
            videoRef?.current?.pause();
            setPlaying(false);
        }
        if (props.videoId === 'overview-video') {
            videoRef?.current?.play();
        }
    }, [props.autoPlay]);

    const timeout = () => {
        setControlsVisible(false);
    };

    const formatTime = (time: number) => {
        const seconds = Math.floor(time % 60);
        return `${Math.floor(time / 60) % 60}:${seconds < 10 ? 0 : ''}${seconds}`;
    };

    const togglePlayPause = () => {
        const video = videoRef.current;
        if (video) {
            if (video.paused) {
                video.play();
                setPlaying(true);
            } else {
                if (props.hideControl) return;
                video.pause();
                setPlaying(false);
            }
        }
    };

    useEffect(() => {
        const pauseVideoHandler = () => {
            if (videoRef.current && !videoRef.current.paused) {
                videoRef.current.pause();
                setPlaying(false);
            }
        };

        window.addEventListener('pause-video', pauseVideoHandler);

        return () => {
            window.removeEventListener('pause-video', pauseVideoHandler);
        };
    }, []);

    function handleTimeline(value: number) {
        if (videoRef.current) {
            videoRef.current.currentTime = (value / 100) * videoRef.current.duration;
            if (videoRef.current.paused || videoRef.current.ended) {
                videoRef.current.play();
            }
            clearTimeout(controlsTimeout);
            controlsTimeout = setTimeout(timeout, 5000);
        }
    }

    function handleVolume(value: number) {
        if (videoRef.current) {
            const newVolume = value / 100;
            setVolume(newVolume * 100);
            videoRef.current.volume = newVolume;
            setMuted(newVolume === 0);
            if (newVolume > 0) {
                videoRef.current.muted = false;
            }
            clearTimeout(controlsTimeout);
            controlsTimeout = setTimeout(timeout, 3000);
        }
    }

    const toggleMute = () => {
        if (videoRef.current) {
            const isMuted = !muted;
            setMuted(isMuted);
            videoRef.current.muted = isMuted;
            setVolume(isMuted ? 0 : 100);
        }
    };

    const toggleFullscreen = () => {
        const videoContainer = videoRef?.current && (videoRef.current.parentElement as any);
        if (!isFullscreen) {
            activateFullScreen(videoContainer);
        } else {
            deactivateFullScreen();
        }
    };

    function handlePlayerClick(e: any) {
        if (e.target.className !== 'play-button') {
            if (props.hideControl) return;
            setControlsVisible(true);
            clearTimeout(controlsTimeout);
            controlsTimeout = setTimeout(timeout, 5000);
        }
    }

    return (
        <div
            className={`video-player ${isFullscreen ? 'fullscreen' : ''}`}
            onClick={e => handlePlayerClick(e)}
            onTouchStart={e => handlePlayerClick(e)}
        >
            <video
                ref={videoRef}
                disablePictureInPicture={true}
                controlsList={'nodownload'}
                autoPlay={props.autoPlay}
                playsInline={true}
                loop={loopVideo}
                muted={true}
                controls={false}
                onDurationChange={video => {
                    setDuration(video.currentTarget.duration);
                }}
                onTimeUpdate={video => {
                    setCurrentTime(video.currentTarget.currentTime);
                }}
                onClick={togglePlayPause}
                onPlay={() => {
                    setPlaying(true);
                }}
                onPause={() => {
                    setPlaying(false);
                }}
                poster={props.placeholder}
            >
                <source src={props.link} type={'video/' + props.filetype} />
            </video>
            <div ref={controlsRef} className={`video-controls ${controlsVisible ? '' : 'hidden'}`}>
                <div className='controls-play' onClick={togglePlayPause}>
                    {playing ? (
                        <svg>
                            <use xlinkHref={'#pause'} />
                        </svg>
                    ) : (
                        <svg>
                            <use xlinkHref={'#play-white'} />
                        </svg>
                    )}
                </div>
                <div className='timeline'>
                    <ReactSlider
                        className='slider'
                        value={duration !== 0 ? (currentTime / duration) * 100 : 0}
                        onChange={handleTimeline}
                        onSliderClick={handleTimeline}
                        renderThumb={(props: any, state: any) => (
                            <div {...props}>
                                <div>
                                    <div />
                                </div>
                            </div>
                        )}
                    />
                </div>
                <p className='time'>{formatTime(duration - currentTime)}</p>
                <div className='volume'>
                    <svg
                        onClick={() => {
                            setShowVolumeBar(true);
                            toggleMute();
                        }}
                    >
                        {muted ? (
                            <use xlinkHref={'#volume-muted'} />
                        ) : volume < 50 ? (
                            <use xlinkHref={'#volume-low'} />
                        ) : (
                            <use xlinkHref={'#volume'} />
                        )}
                    </svg>
                    {showVolumeBar && (
                        <ReactSlider
                            className='volume-slider'
                            value={volume}
                            orientation='vertical'
                            invert
                            onChange={handleVolume}
                            onSliderClick={handleVolume}
                            renderThumb={(props: any, state: any) => (
                                <div {...props}>
                                    <div>
                                        <div />
                                    </div>
                                </div>
                            )}
                        />
                    )}
                </div>
                <div className='fullscreen' onClick={toggleFullscreen}>
                    {isFullscreen ? (
                        <svg>
                            <use xlinkHref={'#fullscreen-exit'} />
                        </svg>
                    ) : (
                        <svg>
                            <use xlinkHref={'#fullscreen-enter'} />
                        </svg>
                    )}
                </div>
            </div>
        </div>
    );
}
