import React, { useContext } from 'react';
import SspProviderContext from '../../utils/SspProvider/context';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import './cardetails.scss';
import { setOverlay } from '../../utils/SspProvider/dispatchFunctions';

export default function CarDetails(props: { CarEnum: number }): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const engine = contentModel.ref('engine').asModel;
    const standardEquipList = contentModel.reflist('serie_equip');
    const optionalEquipList = contentModel.reflist('special_equip');
    const highlightEquipList = contentModel.reflist('highlight_equip');

    const standardCategories = [
        ...new Set(standardEquipList.map(standardEquipItem => standardEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');

    const optionalCategories = [
        ...new Set(optionalEquipList.map(optionalEquipItem => optionalEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');
    const highlightCategories = [
        ...new Set(highlightEquipList.map(highlightEquipItem => highlightEquipItem.asModel.str('equipcategory'))),
    ].filter(c => c !== '' && c !== 'Sonstige' && c !== 'Technik und Sicherheit');

    const subsContainer = (filteredEquipItem: SSPContentVO): JSX.Element => (
        <div className='sub' key={filteredEquipItem.asModel.str('key')}>
            {filteredEquipItem.asModel.str('layertitle') && <p>{filteredEquipItem.asModel.str('layertitle')}</p>}
            {/*{filteredEquipItem.asModel.str('description') && <p>{filteredEquipItem.asModel.str('description')}</p>}*/}
            {/*{filteredEquipItem.asModel.str('details') && <p>{filteredEquipItem.asModel.str('details')}</p>}*/}
            {((filteredEquipItem.asModel.reflist('assets') && filteredEquipItem.asModel.reflist('assets')[0]) ||
                filteredEquipItem.asModel.str('details')) && (
                <svg
                    onClick={() => {
                        filteredEquipItem.asModel.reflist('assets') &&
                            filteredEquipItem.asModel.reflist('assets')[0] &&
                            setOverlay(store, {
                                title: filteredEquipItem.asModel.str('layertitle'),
                                descriptionUpper: filteredEquipItem.asModel.str('details'),
                                overlayImages: filteredEquipItem.asModel.reflist('assets'),
                                packageContents: filteredEquipItem.asModel.reflist('packageItems'),
                            });
                    }}
                >
                    <use xlinkHref='#equipment-info' />
                </svg>
            )}
        </div>
    );

    return (
        <div className='detail-table-area'>
            {props.CarEnum === 1 && (
                <table className='detail-table-overview'>
                    <tbody>
                        {engine.has('fuel_power') ||
                            (engine.str('engine_type') === 'Electric' && (
                                <tr>
                                    <td>
                                        <div>
                                            <h5>
                                                <svg>
                                                    <use xlinkHref='#power' />
                                                </svg>
                                                Leistung
                                            </h5>
                                            <p>
                                                {' '}
                                                {engine.has('fuel_power')
                                                    ? `${engine.str('fuel_power')} KW`
                                                    : engine.str('engine_type') === 'Electric'
                                                      ? `${engine.str('fuel_power2')} KW`
                                                      : '-'}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        {engine.has('motor_gear') && (
                            <tr>
                                <td>
                                    <div>
                                        <h5>
                                            <svg>
                                                <use xlinkHref='#gear' />
                                            </svg>
                                            Getriebe
                                        </h5>
                                        {engine !== undefined && engine.has('motor_gear') ? (
                                            <p>{engine.str('motor_gear')}</p>
                                        ) : (
                                            <p>--</p>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        )}
                        {engine.has('motor_drive') && (
                            <tr>
                                <td>
                                    <div>
                                        <h5>
                                            <svg>
                                                <use xlinkHref='#motor' />
                                            </svg>
                                            Antrieb
                                        </h5>

                                        {engine !== undefined && engine.has('motor_drive') ? (
                                            <p>{engine.str('motor_drive')}</p>
                                        ) : (
                                            <p>--</p>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        )}
                        {((engine.str('engine_type') === 'Electric' && engine.has('fuel2')) ||
                            (engine.str('engine_type') !== 'Electric' && engine.has('fuel_type'))) && (
                            <tr>
                                <td>
                                    <div>
                                        <h5>
                                            <svg>
                                                <use xlinkHref='#fuel' />
                                            </svg>
                                            Kraftstoffart
                                        </h5>
                                        <p>
                                            {engine.str('engine_type') === 'Electric' && engine.has('fuel2')
                                                ? engine.str('fuel2')
                                                : engine.str('engine_type') !== 'Electric' && engine.has('fuel_type')
                                                  ? engine.str('fuel_type')
                                                  : '--'}
                                        </p>{' '}
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
            {props.CarEnum === 2 && (
                <div className='detail-table-highlight'>
                    {highlightCategories.map((category, index) => (
                        <React.Fragment key={`highlight-categories-${index}`}>
                            <div className={'headline open'}>
                                <h5>{category}</h5>
                            </div>
                            <div className='subs-container'>
                                {highlightEquipList
                                    .filter(equipItem => equipItem.asModel.str('equipcategory') === category)
                                    .filter(cat => cat)
                                    .map(filteredEquipItem => subsContainer(filteredEquipItem))}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            )}
            {props.CarEnum === 3 && (
                <div className='detail-table-optional'>
                    {optionalCategories.map((category, index) => (
                        <React.Fragment key={`optional-categories-${index}`}>
                            <div className={'headline open'}>
                                <h5>{category}</h5>
                            </div>
                            <div className='subs-container'>
                                {optionalEquipList
                                    .filter(equipItem => equipItem.asModel.str('equipcategory') === category)
                                    .filter(cat => cat)
                                    .map(filteredEquipItem => subsContainer(filteredEquipItem))}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            )}
            {props.CarEnum === 4 && (
                <div className='detail-table-standard'>
                    {standardCategories.map((category, index) => (
                        <React.Fragment key={`standard-categories-${index}`}>
                            <div className={'headline open'}>
                                <h5>{category}</h5>
                            </div>
                            <div className='subs-container'>
                                {standardEquipList
                                    .filter(equipItem => equipItem.asModel.str('equipcategory') === category)
                                    .filter(cat => cat)
                                    .map(filteredEquipItem => subsContainer(filteredEquipItem))}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    );
}
