import React from 'react';

export default function Luggage(): JSX.Element {
    return (
        <div id='luggage'>
            <div className='dashed-line'>
                <div className='blue-circle' />
                <div className='green-dot green-dot-1' />
                <div className='green-dot green-dot-2' />
            </div>
            <svg>
                <use xlinkHref='#luggageSVG' />
            </svg>
            <h1 className='big-headline center line'>SIE MÖCHTEN IHR GEPÄCK VOR ORT ABGEBEN?</h1>
            <p>
                In der Autostadt WelcomeLounge im Eingangsbereich des Wolfsburger Bahnhofs nehmen wir Ihr Gepäck nach
                der Ankunft mit dem Zug von 08:00 Uhr bis 14:00 Uhr entgegen.
                <br />
                <br />
                Ihr Gepäck von 8:00 Uhr bis 15:30 Uhr dem Autostadt-Team der Mietwagenrückgabe (Parkhäuschen auf dem
                Kurzzeitparkplatz) oder im Untergeschoss der Piazza auszuhändigen.
            </p>
        </div>
    );
}
