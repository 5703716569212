import React, { useContext } from 'react';
import PreviewTile from '../../PreviewTile';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { EditorialContentProps } from '../../Page/interfaces';
import './links.scss';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import SspProviderContext from '../../../utils/SspProvider/context';
import i18next from 'i18next';
import { setOverlay } from '../../../utils/SspProvider/dispatchFunctions';
import sliderSettings from '../../../utils/SliderSettings';
import SortTiles from '../../../utils/SortTiles';

export default function Links(props: EditorialContentProps): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const links: SSPContentVO[] = contentModel.reflist('EditorialLinklist');

    SortTiles(links);

    return (
        <div id='links'>
            <h2>{i18next.t('LinksHeadlineLarge')}</h2>
            <p className='headline-gray'>{i18next.t('Links')}</p>
            <Slider className='swipeable-view' {...sliderSettings}>
                {links.map((link, index) => (
                    <PreviewTile
                        tileType={'small'}
                        img={
                            link.asModel.reflist('Assets')[0]?.asAsset.fileFamily === 'video'
                                ? link.asModel.reflist('Assets')[0].asAsset.posterUrl
                                : link.asModel.reflist('Assets')[0] && link.asModel.reflist('Assets')[0].asAsset.url
                        }
                        h5={link.asModel.str('Title')}
                        p={link.asModel.str('Description')}
                        key={`link- ${index}`}
                        onClick={() => {
                            setOverlay(store, {
                                title: link.asModel.str('Title'),
                                headline: link.asModel.str('Subtitle'),
                                descriptionUpper: link.asModel.str('Description'),
                                link: link.asModel.str('Url'),
                                overlayImages: link.asModel.reflist('Assets')[0] && link.asModel.reflist('Assets'),
                            });
                        }}
                    />
                ))}
            </Slider>
        </div>
    );
}
