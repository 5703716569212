import './infoboxblue.scss';
import i18next from 'i18next';
import { setOverlay } from '../../../utils/SspProvider/dispatchFunctions';
import { OverlayEnum } from '../../../enums';
import { useContext } from 'react';
import SspProviderContext from '../../../utils/SspProvider/context';

function InfoBoxBlue() {
    const store = useContext(SspProviderContext);
    return (
        <div id='info-dark-blue'>
            <h2>{i18next.t('InfoBoxBlueTitle')}</h2>
            <p>{i18next.t('InfoBoxBlueText')}</p>
            <button
                className='transparent-white'
                onClick={() => {
                    setOverlay(store, {
                        enum: OverlayEnum.ExternalContent,
                        media: 'https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2Fgroups%2F593949154614768',
                    });
                }}
            >
                {i18next.t('InfoBoxBlueButton')}
            </button>
        </div>
    );
}

export default InfoBoxBlue;
