import React, { useContext } from 'react';
import PreviewTile from './index';
import SspProviderContext from '../../utils/SspProvider/context';
import Slider from 'react-slick';
import { setOverlay } from '../../utils/SspProvider/dispatchFunctions';
import i18next from 'i18next';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import sliderSettings from '../../utils/SliderSettings';

interface SliderPreviewTileProps {
    id?: string;
    title?: string;
    titleKey?: string;
    tileType?: string;
    onClickCallback?: (item: any) => void;
    items: Array<{
        asModel: {
            reflist: (key: string) => Array<SSPContentVO>;
            str: (key: string) => string;
        };
    }>;
}

const SliderPreviewTile: React.FC<SliderPreviewTileProps> = ({ id, titleKey, items, tileType, onClickCallback }) => {
    const store = useContext(SspProviderContext);

    if (!items || items.length === 0) return null;

    return (
        <div id={id}>
            {titleKey && <h2> {i18next.t(titleKey)}</h2>}
            <Slider {...sliderSettings}>
                {items.map((item, index) => (
                    <PreviewTile
                        key={`slider- ${titleKey} ${index}`}
                        tileType={tileType ? tileType : ''}
                        img={
                            item.asModel.reflist('Assets')[0].asAsset.fileFamily === 'video'
                                ? item.asModel.reflist('Assets')[0].asAsset.posterUrl
                                : item.asModel.reflist('Assets')[0].asAsset.url
                        }
                        button={item.asModel.str('Name')}
                        h5={item.asModel.str('Name')}
                        p={item.asModel.str('DescriptionUpper')}
                        onClick={() => {
                            if (onClickCallback) {
                                onClickCallback(item);
                            } else {
                                setOverlay(store, {
                                    title: item.asModel.str('Name'),
                                    descriptionUpper: item.asModel.str('DescriptionUpper'),
                                    overlayImages: item.asModel.reflist('Assets'),
                                    descriptionLower: item.asModel.str('DescriptionBottom'),
                                    multiSlideOverlay: item.asModel.reflist('SubStoryItem'),
                                    link: item.asModel.str('Url'),
                                });
                            }
                        }}
                    />
                ))}
            </Slider>
        </div>
    );
};

export default SliderPreviewTile;
