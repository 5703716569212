import React, { useContext } from 'react';
import SspProviderContext from '../../../utils/SspProvider/context';
import SSPContentVO from 'sspcontentext/dist/model/SSPContentVO';
import './highlights.scss';
import { setOverlay } from '../../../utils/SspProvider/dispatchFunctions';
import i18next from 'i18next';
import SliderPreviewTile from '../../PreviewTile/SliderPreviewTile';
import SortTiles from '../../../utils/SortTiles';

export default function Highlights(): JSX.Element {
    const store = useContext(SspProviderContext);
    const contentModel = store.state.content ? store.state.content.asModel : new SSPContentVO('').asModel;
    const equipment: SSPContentVO[] = contentModel
        .reflist('HighlightEquipItems')
        .filter(equipItem => equipItem.asModel.target.Kategorie === 'equipitems');
    const technology: SSPContentVO[] = contentModel
        .reflist('HighlightEquipItems')
        .filter(equipItem => equipItem.asModel.target.Kategorie === 'technology');
    const assitanceSystems = contentModel
        .reflist('HighlightEquipItems')
        .filter(equipItem => equipItem.asModel.target.Kategorie === 'Assistance');
    SortTiles(equipment);
    SortTiles(technology);
    SortTiles(assitanceSystems);

    const handleTileClick = (item: SSPContentVO) => {
        setOverlay(store, {
            title: item.asModel.str('Name'),
            descriptionUpper: item.asModel.str('DescriptionUpper'),
            overlayImages: item.asModel.reflist('Assets'),
            descriptionLower: item.asModel.str('DescriptionBottom'),
            multiSlideOverlay: item.asModel.reflist('SubHighlightEquipItem'),
            link: item.asModel.str('Url'),
        });
    };

    return (
        <div id='highlights'>
            <h2>{i18next.t('Highlights')}</h2>
            <div>
                {equipment && equipment.length !== 0 && (
                    <>
                        <p className='headline-gray'>{i18next.t('Equipment')}</p>
                        <SliderPreviewTile items={equipment} tileType={'large'} onClickCallback={handleTileClick} />
                    </>
                )}
            </div>
            <div>
                {technology && technology.length !== 0 && (
                    <>
                        <p className='headline-gray'>{i18next.t('Technology')}</p>
                        <SliderPreviewTile items={technology} tileType={'large'} onClickCallback={handleTileClick} />
                    </>
                )}
            </div>
            <div>
                {assitanceSystems && assitanceSystems.length !== 0 && (
                    <>
                        <p className='headline-gray'>{i18next.t('AssistanceSystems')}</p>
                        <SliderPreviewTile
                            items={assitanceSystems}
                            tileType={'large'}
                            onClickCallback={handleTileClick}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
